import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressWithLabel({ value = 0, color, ...props }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1, color: color || 'red' }}>
                    <LinearProgress
                        variant="determinate"
                        value={value}
                        style={{
                            height: 6,
                            borderRadius: '4px',
                            backgroundColor: '#D9D9D9'
                        }}
                        color='inherit'
                        {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body01-bold" color="text.secondary">
                        {`${Math.round(
                            value,
                        )}%`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}