import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "components/ScrollToTop";
import SessionHelper from "components/SessionHelper";
import {
  ConnectivityProvider, LoadingProvider, SnackbarProvider
} from "contexts";
import theme from "../theme";
import Error from "./Error";
import IELTSMock from "./IELTSMock";
import NotFound from "./NotFound";
import Report from "./Report";
import Workspace from "./Workspace";
import EndAssessment from "./EndAssessment";
import Intro from "./Intro";
import HardwareCheck from "./HardwareCheck";
import SubmitSuccess from "./SubmitSuccess";
import UnsupportedDevice from "./UnsupportedDevice";

// import Home from "./Home";
// import EducationInfo from "./EducationInfo";
// import Dashboard from "./Dashboard";
// import Register from "./Register";
// import RegistrationChoice from "./RegistrationChoice";
// import TestTypeSelection from "./TestTypeSelection";
// import ThankYou from "./ThankYou";
// import TOEFLMock from "./TOEFLMock";
// import AboutUs from "./AboutUs";
// import Login from "./Login";

export default function Screens() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LoadingProvider>
            <ConnectivityProvider>
              <ScrollToTop>
                <SessionHelper>
                  <Routes>
                    <Route path="/error" element={<Error />} />
                    <Route path="/submitted" element={<SubmitSuccess />} />
                    <Route path="/unsupported-device" element={<UnsupportedDevice />} />
                    <Route path="/workspace" element={<Workspace />} />
                    {/* 
                    <Route path="/unsupported" element={<UnsupportedBrowser />} />
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/:type/registration-info" element={<RegistrationChoice />} />
                    <Route path="/:type/education-info" element={<EducationInfo />} />
                    <Route path="/:type/register" element={<Register />} />
                    <Route path="/:type/thank-you" element={<ThankYou />} />
                    <Route path="/toefl/assessment" element={<TOEFLMock />} />
                    <Route path="/home/*" element={<Dashboard />} />
                    <Route path="/choose-test" element={<TestTypeSelection />} /> 
                    <Route path="/" element={<Home />} />
                    */}

                    <Route path="/:type/assessment-intro" element={<Intro />} />
                    <Route path="/hardware-test" element={<HardwareCheck />} />
                    <Route path="/ielts/assessment" element={<IELTSMock />} />
                    <Route path="/:type/report" element={<Report />} />
                    <Route path="/end-assessment" element={<EndAssessment />} />

                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                </SessionHelper>
              </ScrollToTop>
            </ConnectivityProvider>
          </LoadingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
