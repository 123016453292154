import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme=>({
    container: {
        position: "relative",
        width: '100%',
        height: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    videoContainer: {
        overflow: "hidden",
        height: '100%',
        width: 'fit-content',
        maxWidth: '100%',
        borderRadius: '6px'
    },
    gradientBox: {
      width: '100%',
      height: '25%',
      overflow: 'hidden',
      position: 'absolute',
      bottom: '-10px',
      left: '0px',
    },
    gradient: {
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80.5%)',
      width: '100%',
      height: '100%',
      transform: 'rotate(179deg)'
    },
    videoContainerOff: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#353535",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(5),
        borderRadius: '6px'
    },
    bufferingImg: {
        width: '100%',
        height: '100%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        zIndex: -1,
        boxShadow: '0px 0px 3px 0px #00000080'
    }
}));

function VideoIcon(props) {
    return (
        <img
            alt="Camera"
            src="https://assets.languify.in/images/camera-off-icon.svg"
            width={48}
            {...props}
        />
    );
};

function BufferingImage() {
    const classes = useStyles();

    return (
        <Box className={classes.bufferingImg}>
            <Typography variant="h6-medium" color="neutral.clr-600">
                Buffering...
            </Typography>
        </Box>
    );
}

function VideoPreview() {
    const classes = useStyles();
    const [enabled, setEnabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const streamRef = React.useRef(null);
    const videoRef = useRef();

    React.useEffect(() => {
        (async () => {
            if ( enabled) {
                setLoading(true);
                try {

                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: true,
                    });
                    streamRef.current = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        videoRef.current.play();
                    } else {
                        streamRef.current?.getTracks()?.forEach((track) => {
                            track.stop();
                        });
                        setLoading(false);
                    }
                } catch (error) {
                    console.error(error);
                    setEnabled(false);
                    setLoading(false);
                }
            } else {
                streamRef.current?.getTracks()?.forEach((track) => {
                    track.stop();
                });
                setLoading(false);
            }
        })();

        return () => {
            streamRef.current?.getTracks()?.forEach(function (track) {
                track.stop();
            });
            setLoading(false);
        }
    }, [enabled]);

    return (
      <>
        <Box className={classes.container}>
          <Box
              className={classes.videoContainer}
              style={{ display: enabled ? "block" : "none" }}
          >
            <video
                key={enabled ? "video-on" : "video-off"}
                playsInline
                muted
                onPlay={() => {
                    console.log('video-playing...');
                    setLoading(false);
                }}
                onError={() => { console.log('error') }}
                ref={videoRef}
                id="video"
                style={{
                    display: loading ? "none" : "block",
                    width: `calc(100%*1.2)`,
                    height: `calc(100%)*1.2`,
                    marginLeft: `-15%`,
                    marginTop: `-15%`,
                    transform: "rotateY(180deg)",
                    WebkitTransform: "rotateY(180deg)",
                    MozTransform: "rotateY(180deg)",
                  }}
            ></video>
            <Box className={classes.gradientBox}>
              <Box className={classes.gradient}/>
            </Box>
          </Box>
          <Box
              style={{ display: enabled ? "none" : "flex" }}
              className={classes.videoContainerOff}
          >
              <VideoIcon />
              <Typography variant="body01-bold" color="#CACACA" mt={1}>
                  Click to turn on your camera
              </Typography>
          </Box>
        </Box>

        {loading && <BufferingImage />}
      </>
    );
}

export default VideoPreview;