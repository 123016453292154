import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Passage from "./Passage";
import useStore from "utils/ieltsStore";

const useStyles = makeStyles((theme) => ({
  leftSection: {
    width: "50%",
    overflow: "auto",
  },
  rightSection: {
    width: "50%",
    overflowX: "hidden",
    overflow: "auto",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    borderLeft: "2px solid #000",
  },
  fields: {
    display: "flex-inline",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    alignItems: "center",
    justifyContent: 'center'
  },
  dropField: {
    display: "inline-block",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "120px",
    margin: theme.spacing(0,2,-1,1),
    width: 'fit-content',
    padding: theme.spacing(0,2),
    height: "20px",
    borderRadius: "4px",
    border: "1px solid #000",
    backgroundColor: "#fff",
  },
  optionsRoot: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  option: {
    backgroundColor: "#e7e7e7",
    padding: theme.spacing(0, 2),
    borderRadius: "4px",
    width: "fit-content",
    cursor: "pointer",
  },
}));

const Question = ({ data, index, onDrop, options=[], value }) => {
  const classes = useStyles();
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  const handleDrop = (event) => {
    event.preventDefault();
    const option = event.dataTransfer.getData("text/plain");
    onDrop(index, option);
    setCurrentQuestion(index);
  };
  
  const handleClick = () => {
    setCurrentQuestion(index);
  };
  
  const handleDragStart = (event,val) => {
    if (val) {
      event.dataTransfer.setData("text/plain", val);
      setCurrentQuestion(index);
    }
  };

  return (
    <Typography variant="body01-semiBold" component="div" className={classes.fields}>
      {data.content?.map((part, i) =>
        part === "__DND__" ? (
          <Box
            key={`${index}+${i}`}
            className={classes.dropField}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            onClick={handleClick}
            draggable={value!=null}
            onDragStart={(e)=>handleDragStart(e, options[value])}
          >
            {value!==null ? options[value] : ""}
          </Box>
        ) : Array.isArray(part) ? (
          part.map((subPart, j) =>
            subPart === "__DND__" ? (
              <Box
                key={`${index}+${i}+${j}`}
                className={classes.dropField}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onClick={handleClick}
                draggable={value!=null}
                onDragStart={(e)=>handleDragStart(e, options[value])}
              >
                {value!==null ? options[value] : ""}
              </Box>
            ) : (
              subPart
            )
          )
        ) : part
      )}
    </Typography>
  );
};

const ParaDND = ({ groupRange, group, questions, updateQuestion }) => {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [droppedIndexes, setdroppedIndexes] = React.useState([]);

  React.useEffect(() => {
    setdroppedIndexes(() => {
      return questions.map((q) => q?.answer?.value ?? null);
    });
  }, [questions]);

  React.useEffect(() => {
    const answeredIndex = questions.map(q => q.answer.value ?? -1); 
    setOptions(group?.options.filter((_, i) => !answeredIndex.includes(i)) || []);
  }, [group, questions]);
  
  const handleDragStart = (event, option) => {
    event.dataTransfer.setData("text/plain", option);
    event.dataTransfer.setData("text/index", 1);
  };
  
  const handleOnDrop = (index, option) => {
    const correctIndex = index - groupRange[0]+1;
    const opIndex = group.options.findIndex(op => op === option)
    
    setdroppedIndexes(prev => {
      const updatedValues = [...prev];
      updatedValues[correctIndex] = opIndex;
  
      // Update options inside the same state update to ensure fresh state
      setOptions(group?.options.filter((_, i) => !updatedValues.includes(i)) || []);
  
      return updatedValues;
    });
    
    updateQuestion(groupRange[0]-1+correctIndex, opIndex);
  };

  const handleDropBackToOptions = (event) => {
    event.preventDefault();
    const option = event.dataTransfer.getData("text/plain");
    
    const correctIndex = questions.findIndex(q=>q.answer.value === group.options.findIndex(op=>op===option));

    if (!options.includes(option) && option !== undefined) {
      setOptions(prev => [...prev, option]);
    }

    const opIndex = group.options.findIndex(op => op === option)
    
    setdroppedIndexes(prev => prev.map(v => (v === opIndex ? null : v)));

    updateQuestion(groupRange[0]-1+correctIndex, null);
  };

  return (
    <Box style={{ display: "flex", gap: "4px", height: "100%" }}>
      <Box className={classes.leftSection}>
        {group?.questionsRange && (
          <Typography variant="body01-bold" mb={1}>
            Questions {groupRange?.join("-")}
          </Typography>
        )}

        {
          group?.type !== 'para_dnd' ? 
            <>
              <Passage
                active
                title="Read the passage below and answer the following question:"
              >
                {group?.passage}
              </Passage>

              {group?.img && (
                <img
                  alt="Part"
                  src={group?.img}
                  width="90%"
                />
              )}
            </>
            :
            <Box mt={4}>
              {questions.map((ques, index) => (
                <Question
                  key={index}
                  data={ques}
                  index={groupRange[0] - 1 + index}
                  onDrop={handleOnDrop}
                  options={group.options}
                  value={droppedIndexes[index]}
                />
              ))}
            </Box>
        }
      </Box>

      <Box className={classes.rightSection}>
        {group?.description && (
          <Typography 
            variant="body01-bold" mb={2} 
            dangerouslySetInnerHTML={{ __html: group?.description }} 
          />
        )}

        {
          group?.type !== 'para_dnd' && 
            <Box mt={4} mb={8}>
              {questions.map((ques, index) => (
                <Question
                  key={index}
                  data={ques}
                  index={groupRange[0] - 1 + index}
                  onDrop={handleOnDrop}
                  options={group.options}
                  value={droppedIndexes[index]}
                />
              ))}
            </Box>
        }

        <Box
          className={classes.optionsRoot} 
          style={{ flexDirection: group?.type === 'img_dnd' && 'row' }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDropBackToOptions}
        >
          {options.map((option, i) => (
            <Box
              key={i}
              className={classes.option}
              draggable
              onDragStart={(e) => handleDragStart(e, option)}
            >
              <Typography variant="body01-medium">{option}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ParaDND;