import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import useStore from 'utils/ieltsStore';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: 8, borderRadius: 4, margin: "3px 0",
    boxShadow: "0 0px 1px 1px #f2f2f2", width: "100%"
  },
  selectedOption: {
    backgroundColor: theme.palette.primary['clr-50']
  }
}));

function SCQ({ active, currentQuestion, question, index, ...props }) {
  const theme = useTheme();
  const classes = useStyles();

  const [selected, setSelected] = React.useState(-1);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  React.useEffect(() => {
    const v = question?.answer?.value;
    setSelected(v === undefined ? -1 : v);
  }, [currentQuestion, question]);

  const handleChange = (e, v) => {
    setCurrentQuestion(index);
    setSelected(+v);
    question.answer.value = +v;
  };

  return active ? (
    <Box width="100%" {...props} maxHeight={'45vh'} overflow="auto">
      <Box display="flex" mt={3}>
        <Typography variant='body01-bold' pl={1}>
          {index + 1}.
        </Typography>
        <Typography variant='body01-bold' px={1}
          dangerouslySetInnerHTML={{
            __html: (question?.content || question?.question)
          }}
        />
      </Box>
      <RadioGroup
        name="radio-buttons-group"
        sx={{ px: 1, mt: 1 }}
        onChange={handleChange}
        value={selected}
      >
        {question?.options?.map((option, i) => (
          <FormControlLabel
            key={i}
            value={i}
            control={<Radio  size="small" />}
            componentsProps={{ typography: theme.typography['body01-medium']  }}
            label={option}
            classes={{
              label: classes.formControlLabel,
              root: classes.formControl
            }}
            className={selected === i ? classes.selectedOption : ''}
          />
        ))}
      </RadioGroup>
    </Box>
  ) : null;
}

export default SCQ;