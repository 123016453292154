import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';
import { useTheme } from '@mui/styles';

import useStore from 'utils/ieltsStore';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: 8, borderRadius: 4, margin: "3px 0",
    boxShadow: "0 0px 1px 1px #f2f2f2", width: "100%"
  },
  selectedOption: {
    background: theme.palette.primary['clr-50'],
  }
}));

function MCQ({ active, currentQuestion, question, index, ...props }) {
  const theme = useTheme();
  const classes = useStyles();

  const [selected, setSelected] = React.useState([]);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  React.useEffect(() => {
    const values = question?.answer?.value;
    setSelected(values === undefined ? [] : values);
  }, [currentQuestion]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = +e.target.value;
    setCurrentQuestion(index);

    if (checked) setSelected((s) => {
      question.answer.value = [...s, value];

      return question.answer.value;
    });
    else setSelected((s) => {
      question.answer.value = s.filter(v => v !== value);

      return question.answer.value;
    });
  };

  return active ? (
    <Box {...props} overflow="auto" pr={1}>
      <Box display="flex" mt={3}>
        <Typography variant='body01-bold' pl={1}>
          {index + 1}.
        </Typography>
        <Typography
          variant='body01-bold'
          px={1}
          dangerouslySetInnerHTML={{
            __html: (question?.question || question?.content)
          }}
        />
      </Box>
      {question?.options?.map((option, i) => (
        <FormControlLabel
          key={i}
          value={i.toString()}
          onChange={handleChange}
          control={<Checkbox
            checked={selected?.includes(i)} size="small"
          />}
          componentsProps={{ typography: theme.typography['body01-medium'] }}
          label={option}
          classes={{
            label: classes.formControlLabel, root: classes.formControl
          }}
          className={selected?.includes(i) ? classes.selectedOption : ''}
        />
      ))}
    </Box>
  ) : null;
}

export default MCQ;