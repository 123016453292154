import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import { SectionColors } from "./SectionChip";

const useStyles = makeStyles(theme=>({
  container: {
    height: 250, minWidth: 500,
    display: 'flex', 
    gap: theme.spacing(4),
    flexDirection: 'column', justifyContent: 'center'
  },
  bar: {
    width: '130px', height: 30,
    borderRadius: 4,
    display: 'flex', justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    width: '-webkit-fill-available',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
  },
  details: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    flexGrow: 1
  }
}));

function Description({ parts, ques, time, color }) {
    const classes = useStyles();

    return (
        <Box className={classes.details} >
            <Typography variant="body02-bold" color={color}>
                {parts},
            </Typography>
            <Typography variant="body02-bold" color={color}>
                {ques},
            </Typography>
            <Typography variant="body02-bold" color={color}>
                Duration : {Math.round((time || 0) / 60)} mins
            </Typography>
        </Box>
    );
}

const getParts = ({ mockType, name, passages = [], parts = [], questions = [] }) => {
    return mockType === "ielts" ?
        `Parts: ${parts?.length || 0}` :
        ["READING", "LISTENING"].includes(name.toUpperCase()) ?
            `Passages: ${passages?.length || 0}`
            :
            null
};

const getQuestions = ({ mockType, name, passages = [], parts = [], questions = [] }) => {
    return mockType === "ielts" ?
        `Questions: ${questions.length || 0}` :
        ["READING", "LISTENING"].includes(name.toUpperCase()) ?
            `Questions: ${questions?.length || 0}`
            :
            `Questions: ${questions?.length || 0}`;
};


export default function SectionDetails({ sections = [], ...props }) {
    const classes = useStyles();

    return (
      <>
        <Box className={classes.container} {...props}>
          {
            sections.map((section, index) => {
              const sectionName = section.name.toUpperCase();
              const ques = getQuestions(section);
              const parts = getParts(section);
              const { color, backgroundColor } = SectionColors[sectionName];

              return sectionName !== "BREAK" ? (
                <Box style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <Box
                    className={classes.bar} key={index}
                    backgroundColor={backgroundColor}
                  >
                    <Typography variant="body01-bold">
                        {section.name}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.contentContainer}
                    color={backgroundColor}
                  >
                    <Description
                        parts={parts}
                        ques={ques}
                        time={section?.duration}
                        color={color}
                    />
                  </Box>
                </Box>
              ) : null
            })
          }
        </Box>
      </>
    );
}