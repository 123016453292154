import React from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  systemSetting: {
      backgroundColor: '#fff',
      padding: '15px',
    },
    systemSettingContent: {
      height: '100%',
      width: '100%',
      display: 'flex', flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      gap: '5px',
    },
    subTitle1: {
      fontSize: '12px',
      color: '#4E5661',
    },
    troubleShootBtn: {
      textTransform: 'none',
      padding: 0,
      fontSize: '11px',
      textDecoration: 'underline',
    }
}));

export default function BrowserSettingsDialog({ open, onConfirm, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box className={classes.systemSetting}>
        <Box className={classes.systemSettingContent}>
          <Box
            style={{
              width: '100%', display: 'flex', justifyContent: 'flex-end',
            }}
          >
            <img 
              src='https://assets.languify.in/images/browser-setting.svg' alt='mic' 
              style={{ width: '250px', marginBottom: '20px'}}
            />
          </Box>
          <Box style={{ width: '100%' }}>
            <Typography variant='h6-semiBold'>
              Follow these steps:
            </Typography>

            <Box mt={2}>
              <Typography variant='body01-semiBold' component="ul" sx={{ ml: 2, pl: 2, mb: 2 }}>
                <li>Click the above icon in the address bar next to the website's URL.</li>
                <li>Select "Site settings" from the dropdown menu.</li>
                <li>Under 'Permissions,' locate 'Microphone' & and change it to allow.</li>
                <li>Now “Refresh” the page to apply changes.</li>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}