import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

import ReadingSection from "components/IELTSMock/Reading";
import SpeakingSection from "components/IELTSMock/Speaking";
import WritingSection from "components/IELTSMock/Writing";
import ListeningSection from "components/IELTSMock/Listening";
import SubmitSectionDialog from 'dialogs/IELTSSubmitSection';
import { analyseSpokenAnswer, finishMockAttempt, startMockAttempt } from 'services';
import useStore from 'utils/ieltsStore';
import Session from 'utils/Session';
import { exitFullScreen, fullScreen } from 'utils';
import { useLoading, useSnackbar } from 'contexts';
import SaveAnswerDialog from 'dialogs/SaveAnswerDialog';
import { playOrStopAudio } from 'utils/audio';
import { getMockSections } from 'services/sectionService';

const getQuestionDefaultAnswer = (type) => {
  if (type === 'mcq') return [];
  if (type === 'scq') return -1;
  if (type === 'fib') return '';
  if (type === 'notes_fib') return '';
  if (type === 'written') return '';
  if (type === 'spoken') return '';
  return '';
};

export const endTest = (navigate) => {
  const referrer = window?.document?.referrer;
  if (referrer?.includes("languify") || referrer?.includes("localhost")) {
    window.close();
  } else {
    navigate("/home", { replace: true });
  }
}

function IELTSMock(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const snackbar = useSnackbar();
  const loading = useLoading();

  const analysisTasks = React.useRef([]);
  const [savingAnswers, setSavingAnswers] = React.useState(false);
  const [taskCount, setTaskCount] = React.useState(0);

  const setIELTSMock = useStore(state => state.setMock);
  const setTemplate = useStore(state => state.setTemplate);
  const initTest = useStore(state => state.initTest);
  const mockType = useStore((state) => state.mockType);
  const attempt = useStore((state) => state.attempt);
  const section = useStore((state) => state.section);
  const sections = useStore(state => state.sections);
  const nextSection = useStore(state => state.nextSection);
  const nextQuestion = useStore(state => state.next);
  const reset = useStore((state) => state.reset);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);
  const closeDialog = useStore(state => state.closeSubmitSectionDialog);
  const startedAt = useStore(state => state.startedAt);
  const setStartedAt = useStore(state => state.setStartedAt);

  React.useEffect(() => {
    if (attempt) return;
    const attemptId = searchParams.get('aid');
    const templateId = searchParams.get('tid');
    if (!attemptId || !templateId) return;

    (async () => {
      try {
        loading.show();

        const payload = {
          attemptId, templateId
        }
        const { sections, startedByUserAt, ...templateDetails } = await getMockSections(payload);

        if(startedByUserAt){
          navigate(`/error`, { replace: true, state: { attempted: true } });
        }

        const mock = {
          mockType: type,
          sections: sections,
          duration: sections.reduce((acc, section) => acc + section.duration, 0),
          completedSections: 0
        };

        setIELTSMock(mock);
        setTemplate(templateDetails);
        initTest({ attempt: payload.attemptId });
      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
        navigate(`/error`);
      } finally {
        loading.hide();
      }
    })();
  })

  const { questions } = React.useMemo(
    () => sections?.[section] || {}, [sections, section]
  );
  const type = useStore(state => state.sections[section]?.name?.toUpperCase());

  const totalQuestions = React.useMemo(
    () => questions?.length || 0, [questions]
  )

  const beginInterview = async () => {
    if (startedAt) return;

    try {
      loading.show();
      const result = await startMockAttempt(attempt, dayjs());
      setStartedAt(result?.startedAt);
    } catch (error) {
      console.error("error---", error)
      navigate(`/error`, { replace: true, state: { attempted: true }  });
    } finally {
      loading.hide();
    }
  }

  const handleSubmitSection = React.useCallback(() => {
    try {
      playOrStopAudio({ stop: true });
    } catch (error) {
      snackbar.error(error.message);
    }
    setSavingAnswers(true);
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    questions?.forEach((q, serialNumber) => {
      q.answer = {
        questionType: q.type,
        serialNumber, section, attempt, mockType,
        value: getQuestionDefaultAnswer(q.type),
      };
    });
  }, [questions]);

  React.useEffect(() => {
    setCurrentQuestion(0);
  }, [section]);

  // React.useLayoutEffect(() => fullScreen(), []);  

  React.useEffect(() => {
    if (
      type === 'SPEAKING' &&
      taskCount === totalQuestions
    ) {
      handleSubmitSection();
    }
  }, [taskCount]);

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onSaved = async () => {
    if (section + 1 < sections.length) {
      nextSection();
      setSavingAnswers(false);
    } else {
      try {
        await finishMockAttempt(attempt);
      } catch (error) { console.error(error) }

      // exitFullScreen();

      const isApiRequest = searchParams.get('api');
      if (isApiRequest) {
        navigate(`/ielts/report?aid=${attempt}&api=true`, { replace: true });
      } else {
        if (Session.isLoggedIn()) {
          navigate(`/submitted`);
        } else {
          navigate(`/ielts/registration-info?aid=${attempt}`, { replace: true });
        }
      }

      reset();
    }
  };

  const analyseAnswer = (answer) => {
    nextQuestion();
    const task = {
      answer, completed: false, progress: 0, error: false,
      sNo: analysisTasks.current.length
    };

    const handleEvent = (task) => (event, data) => {
      if (event === 'progress') {
        task.progress = data;
      }
      if (event === 'analysed') {
        task.completed = true;
        task.progress = 100;
      }
      if (event === 'error') {
        task.error = true;
      }
    };

    task.eventHandler = handleEvent(task);
    task.service = analyseSpokenAnswer;

    analysisTasks.current.push(task);
    setTaskCount(tc => tc + 1);

    answer.mockType = mockType;
    answer.mockSection = sections?.[section]?._id;
    answer.answer = answer.transcript || '';

    analyseSpokenAnswer(answer, handleEvent(task));
  };

  const sectionProps = {
    onTimeout: handleSubmitSection, analyseAnswer,
    canFinish: analysisTasks.current.length === totalQuestions,
    beginInterview
  };

  return (
    <Box>
      {questions && (
        <>
          {(!savingAnswers && type === 'SPEAKING') && (<SpeakingSection {...sectionProps} />)}
          {(!savingAnswers && type === 'READING') && (<ReadingSection {...sectionProps} />)}
          {(!savingAnswers && type === 'LISTENING') && (<ListeningSection {...sectionProps} />)}
          {(!savingAnswers && type === 'WRITING') && (<WritingSection {...sectionProps} />)}
        </>
      )}

      <SaveAnswerDialog
        isOpen={savingAnswers} onClose={() => setSavingAnswers(false)}
        questions={questions} onSaved={onSaved}
        analysisTasks={analysisTasks}
      />

      <SubmitSectionDialog
        section={type} onSubmit={handleSubmitSection}
      />
    </Box>
  );
}

export default IELTSMock;