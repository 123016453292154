import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WifiIcon from '@mui/icons-material/Wifi';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import SectionHeader from 'components/IELTSMock/SectionHeader';
import SectionFooter from 'components/IELTSMock/SectionFooter';
import Timer from 'components/IELTSMock/Timer';
import ZoomInOut from 'components/ZoomInOut';
import { useConnected } from 'contexts';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '80px',
    maxHeight: '80px',
    padding: theme.spacing(0, 10),
    boxShadow: "0px 2px 8px 0px #02569D1A",
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
    overflow: 'hidden'
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5)
  },
  connBanner: {
    minHeight: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1,3)
  },
  networkStatus: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.shades['clr-offWhite'],
    borderRadius: '4px',
    gap: theme.spacing(1)
  },
  rotatingIcon: {
    color: '#BF7900', fontSize: '20px',
    animation: '$rotate 4s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    }
  }
}));

function SectionShell(
  { active, timerProps = {}, headerProps = {}, footerProps = {}, ...props }
) {
  return active ? (
    <Box sx={{
      mx: 'auto', width: "100%", minWidth: 800, height: "100vh",
      display: "flex", flexDirection: "column"
    }}
    >
      <SectionHeader {...headerProps}>
        <Timer {...timerProps} />
      </SectionHeader>
      <Box
        display="flex" pt={2} alignItems="center"
        flexDirection="column"
        justifyContent={"center"}
        flex="1 1 auto"
        overflow="auto"
        minHeight="0px"
      >
        {props.children}
      </Box>
      {!footerProps.hide && <SectionFooter {...footerProps} />}
    </Box>
  ) : null;
}

export default SectionShell;


export const AppShellNew = ({
  active=true, timerProps = {hide: true}, headerProps = {}, footerProps = {hide: true},
  ...props
}) => {
  const classes = useStyles();
  const { connected } = useConnected();

  const [countdown, setCountdown] = React.useState(3);
  const [openBanner, setOpenBanner] = React.useState(false);
  
  React.useEffect(() => {
    if(!connected){
      setCountdown(3);
      setOpenBanner(true);
    }
  },[connected]);
  
  React.useEffect(() => {
    if(!connected) return;
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setOpenBanner(false);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [connected]);

  return !active ? null : (
    <Box height="100vh" display="flex" flexDirection="column">
      {
        openBanner &&
          <Box 
            className={classes.connBanner}
            style={{ backgroundColor: connected ? '#40AD8B' : '#F26E6D' }}
          >
            {
              connected
                ? <CheckCircleOutlineIcon style={{color: '#fff', marginRight: '8px', fontSize: '18px'}}/>
                : <WarningAmberIcon style={{color: '#fff', marginRight: '8px', fontSize: '18px' }}/>
            }
            <Typography variant='body01-bold' color='neutral.clr-50'>
            {
              connected
                ? 'You are back online.'
                : "Internet disconnected! You can continue the test and submit it once you're back online. Do not refresh the page or close the tab, or you won’t be able to retake it."
            }
            </Typography>
          </Box>
      }
      <Box className={classes.header}>
        <Box>
        {
          timerProps.hide ? null : <Timer {...timerProps} />
        }
        </Box>
        <Box className={classes.rightSection}>
          <Box className={classes.networkStatus}>
            {
              connected 
                ? <WifiIcon style={{color: '#1F5D4A'}}/>
                : <>
                    <AutorenewIcon className={classes.rotatingIcon}/>
                    <Typography variant='body01-bold' color='warning.clr-500'>
                      Reconnecting
                    </Typography>
                  </>
            }
          </Box>

          <ZoomInOut/>
        </Box>
      </Box>
      <Box className={classes.content} flexGrow={1}>
        {props.children}
      </Box>
      {!footerProps.hide && <SectionFooter {...footerProps} />}
    </Box>
  );
}