import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import useStore from 'utils/ieltsStore';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  fib: { marginRight: "1ch", marginLeft: "2px" },
  input: {
    ...theme.typography['body01-semiBold'],
    marginLeft: "4px",
    minWidth: '100px',
    height: '25px',
    borderRadius: "4px",
    padding: 4,
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-400']
  },
  fields: {
    display: 'flex-inline',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3)
  }
}));

export function FIBInput({ index, value, defaultValue, handleChange, handleInput }) {
  const classes = useStyles();
  const inputRef = React.useRef();
  const currentQuestion = useStore(state => state.currentQuestion);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  React.useEffect(() => {
    if (currentQuestion === index) {
      inputRef.current?.scrollIntoView({ block: "nearest" });
      inputRef.current?.focus();
    }
  }, [currentQuestion, index]);

  React.useEffect(() => {
    inputRef.current.value = defaultValue || '';
    adjustWidth();
  }, [defaultValue]);

  const adjustWidth = () => {
    if (inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    };
  }

  return (
    <span className={classes.fib}>
      {/* <b>{index + 1}</b> */}
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => {
          handleChange(e);
          adjustWidth();
        }}
        onInput={(e) => {
          handleInput(e);
          adjustWidth();
        }}
        className={classes.input}
        onFocus={() => setCurrentQuestion(index)}
        spellCheck="false" 
      />
    </span>
  );
}

function FIB({ index, question, ...props }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    setValue(question?.answer?.value || '');
  }, [question]);

  const handleChange = (e) => {
    const value = e.target.value;

    setValue(value);

    if (question) question.answer.value = value;
  };

  return (
    <Typography variant='body01-semiBold' component='div' className={classes.fields} {...props}>
      {question?.content?.map((part, i) => (
        <div key={`${index}-${i}`} style={{ marginBottom: '8px' }}>
          {part === "__FIB__" ? (
            <FIBInput key={index} index={index} handleChange={handleChange} value={value} />
          ) : Array.isArray(part) ? (
            part.map((subPart, j) =>
              subPart === "__FIB__" ? (
                <FIBInput key={`${index}-${i}-${j}`} index={index} handleChange={handleChange} value={value} />
              ) : (
                <span key={`${index}-${i}-${j}`} dangerouslySetInnerHTML={{ __html: subPart }}></span>
              )
            )
          ) : (
            <span key={`${index}-${i}`} dangerouslySetInnerHTML={{ __html: part }}></span>
          )}
        </div>
      ))}
    </Typography>
  );
}

export default FIB;