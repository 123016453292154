import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material'
import HeadsetOutlinedIcon from '@mui/icons-material/HeadsetOutlined';
import { makeStyles } from '@mui/styles';

import DialogTransition from 'components/DialogTransition';
import CustomButton from 'components/CustomButton';


const useStyles = makeStyles(theme=>({
  contentGradiantBox: {
    background: "linear-gradient(284.04deg, #A1FFCE 0%, #D1FFD0 53.43%, #FAFFD1 100%, #F5CE47 100%)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    width: '100%',
  }
}))

export default function PlayAudioDialog({ open, onSubmit }) {
  const classes = useStyles();


  return (
    <Dialog
      keepMounted open={open} TransitionComponent={DialogTransition}
      maxWidth="sm"
    >
      <DialogContent sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '4px' }}>
        <Box className={classes.contentGradiantBox}>
          <HeadsetOutlinedIcon style={{ fontSize: '100px' }}/>
        </Box>
        <Typography variant='body01-semiBold'>
          You will be listening to an audio clip during this test. You will not be permitted to pause or rewind the audio while answering the questions.
        </Typography>
        <Typography variant='body01-bold'>
        To continue, click “Play audio” button.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 4, mb: 2 }}>
        <CustomButton variant="contained" onClick={onSubmit} sx={{ flexGrow: 1 }}>
          Play audio
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}