export const troubleshootSuggestions = [
  'Sit in a quiet place',
  'Check that your external microphone is properly connected.',
  'Stay close to the microphone for best results.'
];

export const troubleshootTranscriptError = [
  'Low-quality or faulty microphones may not capture clear audio. Use good quality microphone.',
  'Being too far or too close to the microphone can affect input quality. Maintain an optimal distance from the mic.',
  'Ambient sounds (e.g., fans, traffic, conversations) can interfere with audio clarity.',
  'Try to avoid mumbled, fast, or unclear speech.',
  'Other running applications may interfere with the mic input, close them & try again.',
];

export const troubleshootMicError = [
  'Check that your external microphone is properly connected & in appropriate proximity.',
  'Don’t use faulty or damaged microphone ports.',
  'Verify that the correct microphone is selected.',
  'The microphone itself may be damaged or non-functional.',
  'Another application might be using or blocking the microphone.',
  'Some antivirus programs may block microphone access for security reasons.',
  'Corporate system sometimes block access for security reasons.',
];

export const Instructions = {
  listening: {
    heading: 'IELTS Listening Academic Test',
    subHeading: (time) => `In total, you have ${time || '15'} minutes to complete this section.`,
    instructions: [
      'You will hear four recordings, each played only once.',
      'You cannot pause the recordings.',
      'The test is divided into four parts, with a total of 40 questions.',
      'Answer as you listen and check your responses before moving to the next part.',
      'At the end of the test, you will have 2 minutes to review all your answers.'
    ]
  },
  reading: {
    heading: 'IELTS Reading Academic Test',
    subHeading: (time) => `In total, you have ${time || '15'} minutes to complete this section.`,
    instructions: [
      'The test consists of three passages divided into three parts, with a total of 40 questions.',
      'You have 60 minutes to complete the test. Manage your time effectively.',
    ]
  },
  writing: {
    heading: 'IELTS Writing Academic Test',
    subHeading: (time) => `In total, you have ${time || '15'} minutes to complete this section.`,
    instructions: [
      <>
        <b>Two tasks:</b>
        <ul style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <li><b>Task 1: </b>Describe, summarise or explain a graph, chart, table or diagram. You need to write at least 150 words in about 20 minutes.</li>
          <li><b>Task 2: </b>Write an essay responding to a point of view, an argument or a problem. You need to write at least 250 words in about 40 minutes</li>
        </ul>
      </>,
      'Plan your responses carefully and use formal academic language.',
    ]
  },
  speaking: {
    heading: 'IELTS Speaking Academic Test',
    subHeading: (time) => `In total, you have ${time || '15'} minutes to complete this section.`,
    instructions: [
      'The IELTS Speaking Test is a one-on-one interview conducted in three parts:',
      <>
        <ul style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <li><b>Part 1: </b>This part tests your ability to give opinions and information on everyday topics and common experiences or situations by answering a range of questions.</li>
          <li><b>Part 2: </b>This part tests your ability to give opinions and information on everyday topics and common experiences or situations by answering a range of questions</li>
          <li><b>Part 3: </b>This part tests your ability to explain your opinions and to analyse, discuss and speculate about issues.  </li>
        </ul>
      </>,
      <>
        <b>Time:</b>
        <ul style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <li>Part 1: 4–5 minutes </li>
          <li>Part 2: 3–4 minutes </li>
          <li>Part 3: 4–5 minutes</li>
        </ul>
      </>
    ]
  }
}
