import React from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import useStore from 'utils/ieltsStore';

const useStyles = makeStyles((theme)=>({
  root: {
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-200'],
    display: "flex",
    width: '90%',
    flexDirection: 'column',
    padding: theme.spacing(4,5),
    gap: theme.spacing(1),
    backgroundColor: '#FCFAFA',
    borderRadius: '4px'
  }
}));

function PartDescription({ part = 1, content = "Answer the questions" }) {
  const classes = useStyles();
  const { type } = useStore((state) => state.sections[state.section] || {}, shallow);

  return (
    <Box className={classes.root}>
      <Typography variant='body01-bold'>
        {type}
      </Typography>
      <Typography variant='body01-bold'>
        Part {part}&nbsp;&nbsp;
      </Typography>
      <Typography variant='body01-medium'>
        {content}
      </Typography>
    </Box>
  );
}

export default PartDescription;