import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useStore from "utils/reportStore";
import GradeReportButton from "../GradeReportButton";
import CustomAccordion from "./CustomAccordion";
import PartWithAnswers from "./PartWithAnswers";

const OVERALL = -1;

export default function Sidebar({ data, ...props }) {
    const section = useStore((state) => state.section);
    const sections = useStore((state) => state.sections);
    const setSection = useStore((state) => state.setSection);
    const { parts = [], questions = [] } = useStore((state) => state.sections?.[section] || {});
    const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
    const setPartIndex = useStore((state) => state.setPartIndex);

    const handleSectionChange = (index, state) => {
        (state) ? setSection(index) : setSection(-1);

        setCurrentQuestion(null);
        setPartIndex(null);
    }

    const handleAnswerClick = (answerIndex, partIndex) => {
        setCurrentQuestion(answerIndex);
        setPartIndex(partIndex);
    }

    return (
        <Box
            height={'97%'} borderRadius={1} mx={2}
            boxShadow='0px 0px 12px rgba(0, 0, 0, 0.16)'
            p={2} width={200} minWidth={200} overflow='auto'
            backgroundColor='#ffffff' mb={1} {...props}
        >
            <GradeReportButton
                ielts mb={2} active={section === OVERALL}
            />

            <Divider orientation="horizontal" />

            <p></p>
            <Typography variant="h6-medium">
                Section Analysis
            </Typography>
            <p></p>

            {sections.map((sec, index) => (
                <Box key={index}>
                    <CustomAccordion
                        open={section === index} label={sec.name}
                        index={index} onChange={handleSectionChange}
                    >
                        {parts.map((part, index) => (
                            <PartWithAnswers
                                index={index}
                                questions={questions}
                                onAnswerClick={handleAnswerClick}
                                key={index} mb={1}
                            />
                        ))}
                    </CustomAccordion>
                    <br />
                </Box>
            ))}
        </Box>
    );
}