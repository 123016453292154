import { Box, Typography } from '@mui/material';
import React from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import { makeStyles } from '@mui/styles';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

import CustomButton from './CustomButton';
import { useSnackbar } from 'contexts';
import { playOrStopAudio } from 'utils/audio';

const isTestingEnv = process.env.REACT_APP_TESTING || false;

const useStyles = makeStyles(theme=>({
  playSound: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid',
    alignItems: 'center',
    borderColor: theme.palette.neutral['clr-600'],
    padding: theme.spacing(4,8),
    borderRadius: '4px',
    background: "url('https://assets.languify.in/images/speaker-gradient.svg')",
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    overflow: 'hidden',
  }
}));

const SpeakerTest = ({ active, handleSuccess=()=>{}}) => {
  const snackbar = useSnackbar();
  const classes = useStyles();
  
  const [playing, setPlaying] = React.useState(false);
  const [played, setPlayed] = React.useState(false);

  const playSound = async() => {
    try{
      const audioInstance  = await playOrStopAudio({source: 'https://assets.languify.in/audio/speaker_audio_test.wav'});
      if (audioInstance) {
        console.log("Audio playing");
        setPlaying(true);
        audioInstance.onended = () => {
          console.log("Audio finished playing");
          setPlaying(false);
          setPlayed(true);
        };
      }
    }catch (error) {
      handleSuccess(false);
      snackbar.error(error.message);
    }
    handleSuccess(true);
  }

  React.useEffect(()=>{
    if(isTestingEnv) {
      handleSuccess(true);
    }
  },[isTestingEnv])

  return !active ? null : (
    <Box mt={6}>
      <Box className={classes.playSound}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <VolumeUpOutlinedIcon/>
          <Typography variant='h5-medium'>Speaker test</Typography>
        </Box>

        <CustomButton onClick={playSound} variant={played ? 'outlined' : 'contained'} disabled={playing}>
          {
            playing
              ? <Typography variant='body01-bold' style={{ display: 'flex', alignItems: 'center' }}>
                  Testing&nbsp; <BarChartIcon style={{color:'#9DC2F2'}}/>
                </Typography>
              : 'Play sound'
          }
        </CustomButton>
      </Box>

      <Box mt={4}>
        <Box mb={2}>
          <Typography variant='h6-semiBold'>
            Suggestions: 
          </Typography>
        </Box>
        <Typography variant='body01-semiBold' component="ol" sx={{ ml: 4, pl: 2, pb: 4 }}>
          <li style={{ marginBottom: '8px' }}>Put on your headphones and click the "Play sound" button to play a sample sound.</li>
          <li style={{ marginBottom: '8px' }}>
If you cannot hear the sound clearly, check whether your speakers or headphones are working properly. Alternatively, try adjusting the volume.
</li>
          <li>If you can hear the sound, click the "Proceed" button. The "Proceed" button get clickable once the test is successful.</li>
        </Typography>
      </Box>
    </Box>
  );
};

export default SpeakerTest;