import React from 'react';
import Box from '@mui/material/Box';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';

import useStore from 'utils/ieltsStore';
import { IconButton } from 'components/CustomButton';

const useStyles = makeStyles(theme=>({
  root: {
    display: "flex" ,
    minHeight: '80px',
    maxHeight: '80px',
    justifyContent: "space-between" ,
    alignItems: "center" ,
    backgroundColor: "#fff" ,
    boxShadow: "0px -2px 8px 0px #02569D1A",
    position: "sticky" ,
    bottom: 0,
    overflow: 'hidden'
  },
  navigationButton: {
    display: 'flex',
    gap: theme.spacing(2),
    position: 'absolute',
    right: '20px',
    top: '-50px'
  },
  submitSection: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100%',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  data: {
    padding: theme.spacing(0,6),
    width: '80vw',
    overflow: 'hidden'
  }
}));


function SectionFooter({ disablePrev, disableNext, hideNav, children }) {
  const classes = useStyles();
  const [next, prev] = useStore((state) => [state.next, state.prev]);
  const openDialog = useStore((state) => state.openSubmitSectionDialog);

  return (
    <Box className={classes.root}>
      <Box flexGrow={1} className={classes.data}>
        {children}
      </Box>
      {!hideNav && (<Box className={classes.navigationButton}>
        <IconButton
          variant="contained" onClick={prev}
          disabled={disablePrev}
          sx={{ height: '40px', width: '40px'}}
        >
          <LeftIcon />
        </IconButton>
        <IconButton
          variant="contained" onClick={next}
          disabled={disableNext}
          sx={{ height: '40px', width: '40px'}}
        >
          <RightIcon />
        </IconButton>
      </Box>
      )}
      <Box className={classes.submitSection}
        variant="contained"
        disabled={disableNext}
        onClick={openDialog}
      >
        <CheckIcon style={{ fontSize: '30px', color: '#003E8C'}} />
      </Box>
    </Box>
  );
}

export default SectionFooter;