import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

import CustomButton from 'components/CustomButton';
import { Instructions } from './constant';
import { getTwoDigitNo } from 'utils';

const useStyles = makeStyles(theme=>({
  root: {
    boxShadow: '0px 2px 8px 0px #00000029',
    minHeight: '70vh',
    height: "550px",
    width: '75%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(1)
  },
  content: {
    padding: '20px',
    flexGrow: 1,
    overflow: 'auto',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '80px',
    alignItems: 'center',
    boxShadow: '0px -8px 32px 0px #00000029',
    padding: theme.spacing(0,4)
  },
  instructions: {
    marginBottom: theme.spacing(2)
  },
  timer: {
    display: 'flex',
    backgroundColor: theme.palette.warning['clr-100'],
    padding: theme.spacing(3,6)
  }
}));

export function ListItem(props) {
  return (
    <Typography
      variant="body2" mt={1} color="#535353" component="div"
      display="inline-flex" {...props}
    >
      <span
        style={{
          marginTop: 6, marginRight: 16, backgroundColor: "#535353",
          padding: 4, height: 8, borderRadius: "50%"
        }}
      />
      <span style={{ display: "inline-block" }}>{props.children}</span>
    </Typography>
  );
}

const SectionInstructions = ({ active=false, name='listening', onNext=()=>{}, timer=null, time, sections }) => {
  const classes = useStyles();

  const [data, setData] = React.useState(Instructions[name]);
  const [timeLeft, setTimeLeft] = React.useState(timer); 

  const isSpeakingSectionOnly = sections?.length === 1 && sections[0]?.name === "Speaking";

  React.useEffect(()=>{
    setData(Instructions[name]);
  },[name]);

  const handleNext = () => {
    onNext();
  };

  React.useEffect(() => {
    if(!timer) return;

    let adjustedTime = isSpeakingSectionOnly ? 60 : timer;
    setTimeLeft(adjustedTime);

    if (active) {
      const timerId = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timerId);
            handleNext();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [active, timer , isSpeakingSectionOnly]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${getTwoDigitNo(minutes)}:${secs < 10 ? '0' : ''}${getTwoDigitNo(secs)}`;
  };

  return !active ? null : (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box display='flex' justifyContent='space-between'>
          <Box className={classes.header}>
            <Typography variant='h5-semiBold'>
              { data.heading }
            </Typography>
            <Typography variant='body01-bold' color='neutral.clr-600'>
              { data.subHeading(time/60) }
            </Typography>
          </Box>
          {
            timer &&
              <Box>
                <Box className={classes.timer}>
                  <Typography variant='h6-medium'>
                  {isSpeakingSectionOnly ? "Starting in" : "Break time"} {formatTime(timeLeft)}
                  </Typography>
                </Box>
              </Box>
          }
        </Box>

        <Divider style={{ borderColor: '#8692A3'}}/>

        <Box mt={4}>
          <Typography variant='h6-semiBold'>
            Instructions to Test Takers
          </Typography>
          <Box mt={2}>
            <Typography variant='body01-semiBold' component="ul" sx={{ ml: 4, pl: 2, mb: 2 }}>
            {
              data.instructions.map((instruction, index) => (
                <li className={classes.instructions} key={index}>{ instruction }</li>
              ))
            }
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.footer}>
        <CustomButton size='medium' onClick={handleNext}>
          Proceed
        </CustomButton>
      </Box>
    </Box>
  );
}

export default SectionInstructions;