import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

import { getLevelByScore } from "utils";
import { Sections } from "./constants";
import useStore from "utils/reportStore";

function Parameter({ category, score }) {

    const { color, label } = getLevelByScore(score);

    return (
        <Box mb={1}>
            <Typography
                variant="body01-bold"
                letterSpacing='-0.019em'
                color='rgba(0, 0, 0, 0.6)'
            >
                {category} :&nbsp;
                <span style={{ color: 'black' }}>{label}</span>
            </Typography>
            <LinearProgressWithLabel value={score} color={color} />
        </Box>
    );
}

export default function ParameterWiseProgress({ parameters = null, title, ...props }) {

    const section = useStore((state) => state.section);
    const sectionName = useStore((state) => state.sections[section]?.name?.toUpperCase());

    let _parameters = [];

    if (parameters && section !== -1) {
        const requiredParameters = Sections[sectionName]?.parameters;
        _parameters = Object.keys(requiredParameters).map((parameter) => (
            {
                category: requiredParameters[parameter],
                score: parameters[parameter]
            }
        ));
    }

    return (
        parameters ?
            <Box {...props}>
                <CenterFlexBox justifyContent='flex-left' mb={2}>
                    <Typography
                        variant="h6-semiBold"
                    >
                        {title}
                    </Typography> &nbsp;
                </CenterFlexBox>
                {
                    _parameters.map((parameter, index) => (
                        <Parameter
                            category={parameter.category}
                            score={parameter.score}
                            key={index}
                        />
                    ))
                }
            </Box> : null
    );
}