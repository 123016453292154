import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useStore from "utils/reportStore";
import CircleProgressbar from "../CircleProgressbar";
import Title from "../Title";
import { IELTSLevels, Sections } from "./constants";

export default function SectionShell({ active, score = 0, children, }) {

    const section = useStore((state) => state.section);
    const sectionName = useStore((state) => state.sections[section]?.name?.toUpperCase());

    const SECTION = Sections?.[sectionName];

    return (
        (active) ? <>
            <Title title={SECTION?.title} />

            <Box mt={4} mb={2} display='flex' gap={6}>
                <Box display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                >
                    {(score >= 0) &&
                        <CircleProgressbar
                            value={score} size={100}
                            percent={false} color='#02569D'
                        />}
                    <Typography variant="body01-semiBold" mt={1}>Band score</Typography>
                </Box>
                <Box>
                    <Typography
                        variant="body01-bold"
                        mb={2} mt={1}
                    >
                        Skill Level : &nbsp;
                        <span style={{ color: 'black' }}>
                            {IELTSLevels[score]?.label || 'NA'}
                        </span>
                    </Typography>
                    <br />
                    <Typography
                        variant="body01-medium"
                        color='rgba(0, 0, 0, 0.6)'
                    >
                        {IELTSLevels[score]?.description || 'NA'}
                    </Typography> <br />
                </Box>
            </Box>

            <Divider /><br />

            {children}

        </> : null
    );
}