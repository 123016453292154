import React from 'react';
import Typography from '@mui/material/Typography';
import useCountDown from 'hooks/useCountDown';

function CountDown({ totalTime, onTimeout, showDays, showHours, ...props }) {
  const [days, hours, minutes, seconds, tl] = useCountDown(totalTime);
  const [warning, setWarning] = React.useState(false);

  React.useEffect(() => {
    const timeLeft = days + hours + minutes + seconds;

    if (timeLeft <= 0 && onTimeout) {
      onTimeout();
    }

    if (Math.round(((totalTime - (tl / 1000)) / totalTime) * 100) <= 90) {
      setWarning(false);
    } else {
      setWarning(true);
    }
  }, [days, hours, minutes, seconds, onTimeout, tl, totalTime]);

  return (
    <Typography
      {...props}
      variant='body02-bold'
      color={warning ? "errorLight.main" : (props.color || "white.main")}
    >
      {days + hours + minutes + seconds <= 0
        ? "00:00"
        : <>
            {showDays && <>{days < 10 ? "0" : ""}{days}:</>}
            {showHours && <>{hours < 10 ? "0" : ""}{hours}:</>}
            {minutes < 10 ? "0" : ""}{minutes}:
            {seconds < 10 ? "0" : ""}{seconds}
          </>
      }
    </Typography>
  );
}

export default CountDown;
