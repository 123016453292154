import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { makeStyles } from '@mui/styles';

import useIELTSStore from 'utils/ieltsStore';
import useTOEFLStore from 'utils/toeflStore';
import { createMockAttempt, getMockAttemptById } from 'services/attemptService';
import { useLoading, useSnackbar } from 'contexts';
import SectionDetails from 'components/SectionDetails';
import CustomButton from 'components/CustomButton';
import { AppShellNew } from 'components/IELTSMock/SectionShell';
import { getMockSections } from 'services/sectionService';
import { verifyToken } from 'services';
import HardwareCheck from './HardwareCheck';

const PrivacyPolicy = "https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex", 
    width: "75%",
    minHeight: '70vh',
    height: "-webkit-fill-available",
    justifyContent: "center", 
    position: "relative" ,
    boxShadow: "0px 2px 8px 0px #00000029",
    padding: theme.spacing(10,5,6,5),
    gap: theme.spacing(8),
  },
  leftSection: {
    display: "flex",
    width: '50%',
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto'
  },
  rightSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }
}));

const getInstructions = (type, isApiRequest, parts = []) => [
  {
    content: <>The purpose of the {type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Mock is to evaluate the English proficiency of people whose native language is not English.</>,
  },
  {
    content: <>The purpose of the {type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Speaking test is to evaluate English proficiency in terms of summarizing listening and reading passages in speech format along with independent speech topics.</>,
    section: "SPEAKING",
  },
  {
    content: <>The purpose of the {type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Writing test is to evaluate English proficiency in terms of independent and integrated writing tasks.</>,
    section: "WRITING",
  },
  {
    content: <>The {type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Reading section assesses how well you can read and understand materials used in an academic environment.</>,
    section: "READING",
  },
  {
    content: <>The {type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Listening section is designed to measure your ability to understand conversations and lectures in English.</>,
    section: "LISTENING",
  }
];

const ieltsInstructions = [
  "Ensure a stable internet connection throughout the test.",
  "Sit in a quiet and calm environment.",
  "You can change answers within the current section but cannot return to previous sections once completed.",
  "The test cannot be paused once started.",
  "Do not press back or refresh the page during the test.",
  "Do not close or switch tabs once the test has started.",
]

const testOverview = {
  Listening: "To assess your ability to understand spoken English in various contexts.",
  Reading: "To assess your ability to comprehend written texts of different styles and genres.",
  Writing: "To evaluate your ability to convey ideas clearly and effectively in written form. ",
  Speaking: "To evaluate  your spoken production and interaction abilities. "
}

function Intro(props) {
  const navigate = useNavigate();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const { type } = useParams();
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [attemptData, setAttemptData] = React.useState({});

  const setTOEFLMock = useTOEFLStore(state => state.setMock);
  const toeflSections = useTOEFLStore(state => state.sections);
  const toeflTime = useTOEFLStore(state => state.totalTime);
  const toeflReferralCode = useTOEFLStore(state => state.referralCode);
  const toeflInitTest = useTOEFLStore(state => state.initTest);

  const setIELTSMock = useIELTSStore(state => state.setMock);
  const ieltsSections = useIELTSStore(state => state.sections);
  const ieltsTime = useIELTSStore(state => state.totalTime);
  const ieltsReferralCode = useIELTSStore(state => state.referralCode);
  const ieltsInitTest = useIELTSStore(state => state.initTest);
  const setTemplate = useIELTSStore(state => state.setTemplate);

  const sections = type === "ielts" ? ieltsSections : toeflSections;
  const time = type === "ielts" ? ieltsTime : toeflTime;
  const referralCode = type === "ielts" ? ieltsReferralCode : toeflReferralCode;
  const initTest = type === "ielts" ? ieltsInitTest : toeflInitTest;

  const isApiRequest = searchParams.get('api');
  const at = searchParams.get('at');

  const handleClick = async () => {
    try {
      loading.show();

      const attemptId = searchParams.get('aid');

      if (!type) {
        navigate("/", { replace: true });
        return;
      }

      if (!attemptId && !at) {
        const attempt = await createMockAttempt({
          mockSections: sections.map((section) => section._id),
          type, referralCode
        });

        initTest({ attempt: attempt._id });
      }

      const sectionsName = sections.map(section => section.name);
      if (sectionsName.includes('Listening') || sectionsName.includes('Speaking')) {
        setStep(1);
        return;
      }

      return navigate(`/ielts/assessment?tid=${attemptData?.templateId}&aid=${attemptData.attemptId}`);
    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loading.hide();
    }
  };

  React.useEffect(() => {
    if(at) return;
    
    (async () => {
      try {
        loading.show();

        const attemptId = searchParams.get('aid');

        if (!type){
          navigate("/", { replace: true });
        }
        else if (sections.length === 0 && !attemptId)
          navigate("/choose-test?t=".concat(type), { replace: true });

        if (attemptId) {
          const { _id, mockSections, user } = await getMockAttemptById(attemptId, false);

          localStorage.setItem('guestId', user.toString());

          const mock = {
            mockType: type,
            sections: mockSections,
            duration: mockSections.reduce((acc, section) => acc + section.duration, 0),
            completedSections: 0
          };

          if (type === 'toefl') setTOEFLMock(mock);
          else if (type === 'ielts') setIELTSMock(mock);

          initTest({ attempt: _id });
        }
      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
      } finally {
        loading.hide();
      }
    })();
  }, [type]);

  React.useEffect(()=>{
    if(!at) return;

    (async () => {
      try {
        loading.show();

        const {data} = await verifyToken(at);
        setAttemptData(data);

        const payload = {
          attemptId: data.attemptId,
          templateId: data.templateId,
        }
        const {sections, ...templateDetails} = await getMockSections(payload);

        localStorage.setItem('guestId', data.user.toString());

        const mock = {
          mockType: type,
          sections: sections,
          duration: sections.reduce((acc, section) => acc + section.duration, 0),
          completedSections: 0
        };

        setIELTSMock(mock);
        setTemplate(templateDetails);
        initTest({ attempt: data.attemptId });
      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
        navigate(`/error`);
      } finally {
        loading.hide();
      }
    })();
  },[type]);

  const proceed = () => {
    return navigate(`/ielts/assessment?tid=${attemptData?.templateId}&aid=${attemptData.attemptId}`);
  }

  return step===0 ? (
    <AppShellNew
      timerProps={{
          hide: true
      }}
    >
      <Box className={classes.root}>
          {/* left section */}
          <Box className={classes.leftSection}>
            <Typography variant='h6-semiBold' mb={4}>
              General Instructions:
            </Typography>
            <Typography variant='body01-medium' component="ul" sx={{ ml: 4, pl: 2 }}>
              <li style={{ marginBottom: '10px'}}>
                {`Total duration: up to ${(time/60) < 40 ? '30 minutes' : '3 hours'}`}
              </li>
              {
                type==='ielts' ? 
                ieltsInstructions.map((instruction, i) => (
                  <li key={i} style={{ marginBottom: '10px'}}>
                    {instruction}
                  </li>
                ))
                :
                getInstructions(type, !at && isApiRequest, sections).map((instruction, i) => (
                <li key={i} style={{ marginBottom: '20px'}}>
                    {instruction.content}
                </li>
              ))}
            </Typography>

            <Typography variant='h6-semiBold' mt={5}>
              Test Overview:
            </Typography>
            <Typography variant='body01-medium' component="ul" sx={{ ml: 4, pl: 2 }}>
              {
                sections.map((section,i)=>(
                  <li key={i} style={{ marginTop: '5px'}}><b>{section.name}:</b> &nbsp;
                    {testOverview[section.name]}
                  </li>
                ))
              }
            </Typography>
          </Box>

          {/* right section */}
          <Box className={classes.rightSection}>
            <Typography variant="h5-semiBold">Hi there,</Typography>
            <Typography variant='h6-medium' color={'neutral.clr-600'}>
              Welcome to the Online {type.toUpperCase()} Practice Test.

              {
                !at && !isApiRequest && <> powered by&nbsp;
                  <span style={{ color: "#02569D", fontWeight: 'bold' }}>
                    Languify!
                  </span></>
              }
            </Typography>

            <Typography variant='h6-medium' color={'neutral.clr-600'}>
              This test evaluates your English proficiency in&nbsp;
              {sections.map((sec, i) => (sections.length===1) 
                ? `${sec?.name}` 
                : (i === sections.length - 1) 
                  ? `and ${sec?.name}` : `${sec?.name}, `)}.
            </Typography>

            <SectionDetails sections={sections} />

            <Box textAlign="center">
              <CustomButton
                onClick={handleClick}
                sx={{ width: '100%' }}
                size='medium'
                endIcon={<ArrowRightAltIcon color="white" />}
              >
                Let's get started
              </CustomButton>
              {!at && !isApiRequest &&
                <Typography variant='body02-medium' color="#00000099" fontWeight={500}>
                  By clicking Let's get started you accept&nbsp;
                  <Typography
                    component="a" variant='body02-medium' color="#02569D" target="_blank"
                    href={PrivacyPolicy} style={{ textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    terms and conditions
                  </Typography>
                </Typography>}
            </Box>
          </Box>

      </Box>
    </AppShellNew>
  ) : <HardwareCheck proceed={proceed}/>;
}

export default Intro;