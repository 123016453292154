import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import { PartsNavigation } from 'components/IELTSMock/Reading';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import PartDescription from 'components/PartDescription';
import WritingQuestion from './WritingQuestion';
import { AppShellNew } from 'components/IELTSMock/SectionShell';
import useStore from 'utils/ieltsStore';
import SectionSlider from 'components/SectionSlider';

const useStyles = makeStyles(theme=>({
  leftSection: {
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  interviewSection: {
    width: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function WritingSection({ onTimeout, beginInterview=()=>{} }) {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(0);
  const [leftWidth, setLeftWidth] = React.useState(50);

  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);
  const sections = useStore(state => state.sections);
  const section = useStore(state => state.section);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(() => groups?.[question.group], [question, groups]);

  const setPartIndex = useStore(state => state.setPartIndex);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  const handleNext = () =>{
    if(current===0) beginInterview();
    setCurrent((x) => x + 1);
  }

  React.useEffect(() => {
    setPartIndex(0);
    setCurrentQuestion(0);
  }, []);

  return (
    <AppShellNew
      active
      timerProps={{
        totalTime: current===0 ? time[current] : sections[section]?.duration,
        onTimeout: current === 0 ? handleNext : onTimeout
      }}
      headerProps={{ hideFinish: current !== 1, disableFinish: false }}
      footerProps={{
        hide: current !== 1,
        disableNext: questions?.length === currentQuestion+1,
        disablePrev: currentQuestion === 0,
        children: <PartsNavigation />
      }}
    >
      <SectionInstructions
        type="ielts"
        active={current === 0}
        onNext={handleNext}
        name={"writing"}
        time={duration}
      />

      {current === 1 && (
        <Box className={classes.interviewSection}>
          <PartDescription
            part={partIndex + 1}
            content={part?.description}
          />
          <QuestionsContainer>
            <Box
              display="flex" width='100%' flex="1 1 auto" height="56vh"
              justifyContent="space-between"
            >
              <Box className={classes.leftSection} width={`${leftWidth}%`} 
                onDragOver={(e)=>e.preventDefault()}
                onDragStart={(e)=>e.preventDefault()}
              >
                {group?.description && (
                  <Typography
                    variant='body01-bold'
                    dangerouslySetInnerHTML={{ __html: group.description }}
                  />
                )}
                {group?.image && (
                  <Box p={2} border="1px solid lightgrey" m={2}>
                    <img
                      alt="Part"
                      src={group?.image}
                      width="100%"
                    />
                  </Box>
                )}
              </Box>

              <SectionSlider setLeftWidth={setLeftWidth}/>

              <Box width={`${100 - leftWidth}%`}>
                <WritingQuestion active question={question} />
              </Box>
            </Box>
          </QuestionsContainer>
        </Box>
      )}
    </AppShellNew>
  );
}

export default WritingSection;