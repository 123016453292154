import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { TextArea } from 'components/CustomFields';

const isTestingEnv = process.env.REACT_APP_TESTING || false;

const useStyles = makeStyles(theme=>({
  root: {
    minWidth: "48%", 
    width: "100%",
    height: '100%',
    padding: theme.spacing(2,4)
  },
  wordCount: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  }
}));

function WritingQuestion({ active, question }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [wordCount, setWordCount] = React.useState(0);
  const [rows, setRows] = React.useState(15);
  const rootRef = React.useRef(null);

  const [selectedText, setSelectedText] = React.useState("");
  const textAreaRef = React.useRef(null);

  const handleTextSelect = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      if (start !== end) {
        setSelectedText(textArea.value.substring(start, end));
      }
    }
  };

  const handleTextDeselect = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      if (start === end) {
        setSelectedText("");
      }
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);

    question.answer.value = e.target.value;
  };

  React.useEffect(() => {
    const wc = value?.trim()?.split(/\s+/)?.filter(Boolean).length;
    setWordCount(value ? wc : 0);
  }, [value]);

  React.useEffect(() => {
    if (question.answer) setValue(question.answer.value);
  }, [question]);

  React.useEffect(() => {
    const updateRows = () => {
      if (rootRef.current) {
        const rootHeight = rootRef.current.clientHeight-30;
        const rowHeight = 24;
        setRows(Math.floor(rootHeight / rowHeight));
      }
    };

    const observer = new ResizeObserver(updateRows);
    if (rootRef.current) observer.observe(rootRef.current);

    return () => observer.disconnect();
  }, []);

  return active ? (
    <Box ref={rootRef} className={classes.root}>
      
      <TextArea
        rows={rows}
        inputRef={textAreaRef}
        value={value}
        onChange={handleChange}
        spellCheck={false}
        data-gramm={false}
        data-gramm_editor={false}
        data-enable-grammarly={false}
        onPaste={(e) => isTestingEnv ? null : e.preventDefault()}
        autoComplete="off"
        placeholder="Write your answer here..."
        onSelect={handleTextSelect}
        onMouseUp={handleTextDeselect}
        onKeyUp={handleTextDeselect}  
      />
      <Box className={classes.wordCount}>
        <Box>
          {
            selectedText?.length && wordCount>1 
              ? <Typography variant='body01-medium'>
                  *Drag and drop selected text to rearrange within the field.
                </Typography> 
              : null
          }
        </Box>
        <Typography variant='body01-bold'>
          Words:&nbsp;
          <span>
            {wordCount}
          </span>
        </Typography>
      </Box>
    </Box>
  ) : null;
}

export default WritingQuestion;