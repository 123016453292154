import { Box } from "@mui/material";
import { ErrorContainer } from "./Error";
import { useSearchParams } from "react-router-dom";

function UnsupportedDevice() {

  const [searchParams] = useSearchParams();

  const fromReport = searchParams.get("from") === "report";

  return (
    <Box>
      <ErrorContainer
        heading={fromReport ?
          "Report Not Available on Mobile/Tablet" :
          "Test Not Available on Mobile/Tablet"
        }
        subheading={fromReport ?
          "This report contains detailed insights that cannot be accessed on a mobile or tablet device. Please use a laptop or desktop for viewing the report." :
          "This test cannot be taken on a mobile/tablet device. Please use a laptop or desktop for the best experience. We recommend using the Chrome or Edge browser for optimal performance."}
      />
    </Box>
  );
}

export default UnsupportedDevice;