import Client from "./_client";

export const generateMock = async ({ type, sections = [], attemptId, short }) => {
    let url = "/mockSection/generate-mock?";

    if (attemptId) url += `&attempt=${attemptId}`;
    if (type) url += `&type=${type}`;
    if (sections) url += `&sections=${sections}`;
    if (short) url += `&short=${short}`;

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! cannot generate the mock. Please try again!"
        );
    }

    return result?.data;
};

export const getMockDetails = async (id) => {
  let url = `/mockSection/template/${id}`;

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! failed to fetch mock. Please try again!"
    );
  }else{
    return result?.data;
  }
};

export const getMockSections = async (payload) => {

  const result = await new Client({
    path: `/mockSection/template/to-be-attempted`,
    payload
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable fetch mock template. Please try again!"
    );
  }

  return result?.data;
}