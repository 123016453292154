import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogActions, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import DialogTransition from 'components/DialogTransition';
import CustomButton, { LinkButton } from 'components/CustomButton';
import { useConnected } from 'contexts';

const useStyles = makeStyles(theme=>({
  root: { 
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  messageContainer: {
    height: '250px',
    width: '550px',
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    border: '1px solid #CCD4DE',
    borderRadius: '8px',
    gap: theme.spacing(2)
  }
}));

export default function NetworkErrorScreenDialog({ connected }) {
  const classes = useStyles();

  const [countdown, setCountdown] = React.useState(3);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(!connected){
      setCountdown(3);
      setOpen(true);
    }
  },[connected]);
  
  React.useEffect(() => {
    if(!connected) return;
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setOpen(false);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [connected]);

  return (
    <Dialog
      keepMounted open={open} TransitionComponent={DialogTransition}
      maxWidth="lg"
    >
      <DialogContent sx={{ p: 0 }}>
        <Box className={classes.messageContainer}
          style={{ backgroundColor: connected ? '#DCFCE7' : '#FFE6E3' }}
        >
          <img 
            src={
              connected 
                ? "https://assets.languify.in/images/success-icon.svg" 
                : "https://assets.languify.in/images/error-icon.svg" 
            }
            alt="error" style={{ marginBottom: '10px' }}
          />
          <Typography variant="h5-medium">
            {
              connected 
                ? 'Connected' 
                : "You've lost Internet Connection"
            }
          </Typography>
          <Box>
          {
            connected 
              ? <Typography variant="body01-semiBold">
                  Continue answering without refreshing or closing the tab.
                </Typography>
              : 
                <>
                  <Typography variant="body01-semiBold">
                    Please reconnect to a stable internet connection immediately.
                  </Typography><br/>
                  <Typography variant="body01-semiBold">
                    Do not refresh or close this tab- otherwise you won’t be able to retake the test.
                  </Typography>
                </>
          }
          </Box>
          {
            connected ?
              <Typography variant="body01-semiBold">
                Closing in {countdown} seconds...
              </Typography>
              : null
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export function NoNetworkDialog({ 
  isOpen, onSubmit, onClose, buttonLabel 
}) {
  const { connected, getConnectionStatus } = useConnected();

  return (
    <Dialog
      keepMounted open={isOpen}
      TransitionComponent={DialogTransition}
      sx={{
        '& .MuiDialog-paper': {
          width: '500px',
        },
        zIndex: 10,
      }}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ m: 0, px: 4}}>
        <Box display='flex' justifyContent='space-between' alignItems='center' pb={2}>
          <Typography variant='h5-medium' color={connected ? 'success.clr-700' : 'danger.clr-700'}>
          {
            connected
              ? "You're back online!"
              : "Internet disconnected!"
          }
          </Typography>
          {
            (onClose) &&
              <IconButton onClick={onClose}>
                <CloseIcon/>
              </IconButton>
          }
        </Box>
        <Divider/>
      </DialogTitle>
      <DialogContent sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography variant='h6-regular'>
          {
            connected
              ? "You can now proceed to submit your responses to complete this section." 
              : "Please reconnect to a stable internet connection immediately. Do not refresh or close this tab otherwise you will be unable to retake the test!"
          }
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 4, mb: 2, display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <CustomButton 
          variant="contained" 
          onClick={onSubmit} sx={{ flexGrow: 1, width: '100%' }} 
          size='medium'
          disabled={!connected}
        >
          {
            connected
              ? buttonLabel ?? "Submit"
              : buttonLabel ??  "Try again"
          }
        </CustomButton>

        {
          !connected &&
            <LinkButton onClick={getConnectionStatus}>
              Click to check internet manually
            </LinkButton>
        }
      </DialogActions>
    </Dialog>
  )
}