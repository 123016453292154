import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MCQ from 'components/MCQ';
import SCQ from 'components/SCQ';
import Passage from 'components/Passage';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import { AppShellNew } from 'components/IELTSMock/SectionShell';
import useStore from 'utils/ieltsStore';
import PartDescription from 'components/PartDescription';
import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import FIB from 'components/FIB';
import TickMarkSection from 'components/TickMarkSection';
import TableFIBSection from '../TableFIBSection';
import ParaDND from 'components/ParaDND';
import SectionSlider from 'components/SectionSlider';

const useStyles = makeStyles(theme=> ({
  root: {
    display: 'flex',
    alignItems: "center" ,
    gap: theme.spacing(2),
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: 0, height: 0
      },
  },
  content: {
    height: "100%",
    display: "flex", flex: "1 1 auto", 
    justifyContent: "space-between", 
    overflow: 'hidden',
  },
  partRoot: {
    display: 'flex',
    padding: "15px 20px",
    gap: '5px',
    alignItems: 'center',
  },
  part: {
    padding: '15px 20px',
    borderTop: '3px solid #CCD4DE',
    marginLeft: '4px', 
    marginRight: '4px'
  },
  buttonRoot: {
    padding: '10px 0px',
    borderTop: '3px solid',
    borderColor: theme.palette.neutral['clr-200']
  },
  buttons: {
    padding: theme.spacing(1,2),
    border: '2px solid',
    borderColor: theme.palette.primary['clr-300'],
    borderRadius: '4px',
    cursor: 'pointer'
  },
  interviewSection: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftSection: {
    overflow: 'auto'
  },
  rightSection: {
    overflowX: 'hidden',
    overflow: 'auto',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  }
}));

export const PartsNavigation = () => {
  const classes = useStyles();
  const { questions = [], parts } = useStore(
    (state) => state.sections?.[state.section] || {}
  );
  const currentQuestion = useStore((state) => state.currentQuestion);
  const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
  const setPartIndex = useStore((state) => state.setPartIndex);
  const isRecording = useStore((state) => state.isRecording);
  const questionRefs = useRef([]);
  const [partFigures, setPartFigures] = React.useState([]);

  const part = React.useMemo(() => 
    parts?.[questions?.[currentQuestion].part]
  , [questions, parts, currentQuestion]);

  // Find the current active part
  const activePart = questions?.[currentQuestion]?.part;

  const onSelect = (pIndex, qIndex) => {
    setPartIndex(pIndex);
    setCurrentQuestion(qIndex);
  };

  React.useEffect(() => {
    if (questionRefs.current[currentQuestion]) {
      questionRefs.current[currentQuestion].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentQuestion]);

  const handleChangePart = (part, questionIndex) => {
    if(activePart!==part){
      setCurrentQuestion(questionIndex);
    }
  }

  React.useEffect(() => {
    setPartFigures((prevFigures) => {
      const newFigures = [...prevFigures];

      // Get the highest part index from questions
      const maxPartIndex = Math.max(...questions.map((ques) => ques.part), 0);

      for (let i = 0; i <= maxPartIndex; i++) {
        const totalQuestions = questions.filter((ques) => ques.part === i).length;

        // Ensure each part exists in the array
        if (!newFigures[i]) {
          newFigures[i] = { totalAttempted: 0, totalQuestions };
        } else {
          newFigures[i] = { ...newFigures[i], totalQuestions };
        }
      }

      // Update the active part's attempted questions
      const partQuestions = questions.filter((ques) => ques.part === activePart);
      const totalAttempted = partQuestions.filter((ques) =>
        ["scq"].includes(ques.type) ? ques?.answer?.value >= 0 : Boolean(ques?.answer?.value)
      );

      if (partQuestions.length === totalAttempted.length) {
        part.completed = true;
      }

      // Ensure the active part is updated correctly
      if (!newFigures[activePart]) {
        newFigures[activePart] = { totalAttempted: 0, totalQuestions: partQuestions.length };
      } else {
        newFigures[activePart] = { 
          ...newFigures[activePart], 
          totalAttempted: totalAttempted.length,
          totalQuestions: partQuestions.length
        };
      }

      return newFigures;
    });
  }, [questions, activePart, currentQuestion, part]);
  
  return (
    <Box className={classes.root}>
      {questions?.map((q, j) => {
        const isSamePart = q.part === activePart;
        return (
          <React.Fragment key={j}>
            {q.part !== questions?.[j - 1]?.part && (
              <Box className={classes.partRoot}>
                <Box
                  padding="15px 20px"
                  borderTop="3px solid"
                  borderColor={parts[q.part]?.completed ? "#009970" : '#CCD4DE'}
                  style={{ cursor: 'pointer' }}
                  ml={4}
                  mr={3}
                  onClick={()=> handleChangePart(q.part, j)}
                >
                  <Typography variant="body01-bold">
                    Part&nbsp;{q.part + 1}
                  </Typography>

                </Box>
                {
                  !isSamePart &&
                    <Typography variant='body01-medium' color='neutral.clr-400'>
                      {partFigures?.[q.part]?.totalAttempted || 0}&nbsp;of&nbsp;{partFigures?.[q.part]?.totalQuestions || 0}
                    </Typography>
                }
              </Box>
            )}

            {/* Hide questions that do not belong to the active part */}
            {isSamePart && (
              <Box
                className={classes.buttonRoot}
                ref={(el) => (questionRefs.current[j] = el)}
                style={{
                  borderColor: ["scq"].includes(q.type)
                    ? q?.answer?.value >= 0 ? "#009970" : "#8692A3"
                    : q?.answer?.value ? "#009970" : "#8692A3",
                }}
              >
                <Box
                  key={j}
                  disabled={isRecording || currentQuestion !== j}
                  className={classes.buttons}
                  style={{ border: currentQuestion !== j && "none" }}
                  onClick={() =>
                    q.answer.submitted || currentQuestion === j
                      ? {}
                      : onSelect(q.part, j)
                  }
                >
                  <Typography
                    variant="body01-bold"
                    color={currentQuestion === j ? "primary" : "#4E5661"}
                  >
                    {j + 1}
                  </Typography>
                </Box>
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

function ReadingSection({ onTimeout, beginInterview=()=>{} }) {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(0);
  const [leftWidth, setLeftWidth] = React.useState(50);

  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);
  const sections = useStore(state => state.sections);
  const section = useStore(state => state.section);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(() => groups?.[question.group], [question, groups]);

  const handleNext = () =>{
    if(current===0) beginInterview();
    setCurrent((x) => x + 1);
  } 

  const footerProps = React.useMemo(() => {
    return {
      hide: current === 0,
      children: <PartsNavigation />,
      disableNext: currentQuestion + 1 === questions?.length,
      disablePrev: currentQuestion === 0,
    }
  }, [current, currentQuestion, questions]);

  const groupRange = group?.questionsRange;

  const groupQuestions = questions.slice(groupRange[0] - 1, groupRange[1]);
  
  const updateQuestion = React.useCallback((index, newValue) => {
    questions[index].answer.value = newValue;
    return questions[index].answer.value;
  },[questions])

  return (
    <AppShellNew
      active
      timerProps={{
        totalTime: current===0 ? time[current] : sections[section]?.duration,
        onTimeout: current===0 ? handleNext : onTimeout
      }}
      headerProps={{ hideFinish: current !== 1 }}
      footerProps={footerProps}
    >
      <SectionInstructions
        type="ielts"
        active={current === 0}
        onNext={handleNext}
        name={"reading"}
        time={duration}
      />
      {current === 1 && (
        <Box className={classes.interviewSection}>
          <PartDescription
            part={partIndex + 1}
            content={part.description}
          />
          <QuestionsContainer>
            {
              group?.type === 'tick_mark' ?
                <TickMarkSection
                  questions={groupQuestions}
                  group={group}
                  currentQuestion={currentQuestion}
                  groupRange={groupRange}
                /> 
                :
                group?.type === 'table_fib' ?
                  <TableFIBSection
                    questions={groupQuestions}
                    group={group}
                    currentQuestion={currentQuestion}
                    groupRange={groupRange}
                    updateQuestion={updateQuestion}
                  />
                  :
                  group?.type === 'passage_dnd' ?
                    <ParaDND
                      questions={groupQuestions} 
                      group={group} 
                      groupRange={groupRange}
                      updateQuestion={updateQuestion}
                    />
                    :
                    <Box className={classes.content}>
                      <Box className={classes.leftSection} width={`${leftWidth}%`}>
                        <Passage
                          active
                          title="Read the passage below and answer the following question:"
                        >
                          {
                            ['scq','mcq','passage_dnd','fib'].includes(question.type) 
                              ? group.passage
                              : part?.passage || ""
                          }
                        </Passage>
                        {group?.image && (
                          <img
                            alt="Part"
                            src={group?.image}
                            width="100%"
                          />
                        )}
                      </Box>
                      <SectionSlider setLeftWidth={setLeftWidth}/>
                      <Box className={classes.rightSection} width={`${100 - leftWidth}%`}>
                        {group?.questionsRange && (
                          <Typography fontWeight={600} variant="subtitle1" mb={1}>
                            Questions {groupRange?.join("-")}
                          </Typography>
                        )}

                        {group?.description && (
                          <Typography
                            variant='body01-bold' mb={2} dangerouslySetInnerHTML={{
                              __html: group?.description
                            }}
                          />
                        )}

                        {(group?.image || group?.content) && (
                          <Box width="100%" border="1px solid grey" m={2}>
                            {group?.content && (
                              <Box
                                width="100%"
                                p={2}
                                dangerouslySetInnerHTML={{
                                  __html: group?.content || ""
                                }}
                              />
                            )}
                          </Box>
                        )}

                        {groupQuestions?.map((question, i) => (
                          <React.Fragment key={i}>
                            {question.type === 'scq' && (
                              <SCQ
                                index={groupRange[0] + i - 1}
                                active width="-webkit-fill-available"
                                currentQuestion={currentQuestion}
                                question={question}
                              />
                            )}
                            {question.type === 'mcq' && (
                              <MCQ
                                index={groupRange[0] + i - 1}
                                active width="-webkit-fill-available"
                                currentQuestion={currentQuestion}
                                question={question}
                              />
                            )}
                            {question.type === 'fib' && (
                              <FIB
                                index={groupRange[0] + i - 1}
                                width="-webkit-fill-available" ml={2}
                                question={question}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </Box>
                    </Box>
            }
          </QuestionsContainer>
        </Box>
      )}
    </AppShellNew>
  );
}

export default ReadingSection;