import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, Divider, IconButton, LinearProgress, Typography } from '@mui/material';

import DialogTransition from 'components/DialogTransition';
import useStore from 'utils/ieltsStore';
import shallow from 'zustand/shallow';
import { analyseSection } from 'services';
import CustomButton from 'components/CustomButton';

export default function SaveAnswerDialog({ 
  isOpen, onClose, questions, 
  onSaved, analysisTasks,
}) {
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [retry, setRetry] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [analysed, setAnalysed] = React.useState(-1);

  const [section, attempt, mockType, sections] = useStore(state => [
    state.section, state.attempt, state.mockType, state.sections
  ], shallow);

  const sectionType = sections?.[section]?.name?.toUpperCase();

  React.useEffect(() => {
    if (isOpen) {
      setAnalysed(0);
      setProgress(0);

      if (sectionType === "SPEAKING") {
        const totalTasks = analysisTasks.current.length;
        setTotal(totalTasks);
        setErrors(new Array(totalTasks).fill(false));

        const failedTasks = analysisTasks.current.filter(t => t.error);
        if (retry > 0 || failedTasks.length) {

          failedTasks.forEach((task) => {
            task.error = false;
            task.progress = 0;
            task.completed = false;
            task.service(task.answer, task.eventHandler);
          });
        }

        const progressInterval = setInterval(() => {
          let _progress = 0;
          let _analysed = 0;
          analysisTasks.current.forEach((task, i) => {
            _progress += task.progress;
            if (task.completed) _analysed += 1;
            if (task.error) {
              setErrors((errors) => ([
                ...errors.slice(0, i), true, ...errors.slice(i + 1)
              ]));
            }
          });

          setAnalysed(_analysed);
          setProgress(Math.floor(_progress / totalTasks));

          if (_analysed === totalTasks) {
            clearInterval(progressInterval);
          }
        }, 1000);

        return () => clearInterval(progressInterval);
      } else {
        setErrors([false]);
        setTotal(1);

        const answers = questions.map((q) => ({
          questionType: q.type || q.answer.questionType,
          answer: q.answer.value,
        }));

        const progressInterval = setInterval(() => {
          setProgress((p) => {
            if (p >= 90) {
              clearInterval(progressInterval);
              return p;
            } else return p + Math.round(Math.random() * 20);
          });
        }, 1000);

        analyseSection(
          {
            section, attempt, mockType, answers,
            mockSection: sections?.[section]?._id,
          },
          (event, data) => {
            if (event === 'analysed') {
              setProgress(100);
              onSaved();
            }
            if (event === 'error') {
              setProgress(100);
              console.error("ERROR_SAVING_ANSWER:", data);
              setErrors([data]);
            }
          }
        );

        return () => clearInterval(progressInterval);
      }
    }
  }, [isOpen, retry]);

  React.useEffect(() => {
    if (analysed === total && !!!errors.find(e => e !== false)) {
      setProgress(100);
      onSaved();
    }
  }, [analysed, total, errors, onSaved]);

  return (
    <Dialog
      keepMounted open={isOpen} TransitionComponent={DialogTransition}
      sx={{
        '& .MuiDialog-paper': {
          width: (!!errors.find(e => e !== false)) ? '500px' : '450px'
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ m: 0, px: 4}}>
        <Box display='flex' justifyContent='space-between' alignItems='center' pb={2}>
          {
            (!!errors.find(e => e !== false)) ?
            <>
              <Typography variant='h5-medium' color='danger.clr-700'>
                Upload incomplete!
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon/>
              </IconButton>
            </>
            : <Typography variant='h5-medium' color='warning.main'>
                Your answers are being saved...
              </Typography>
          }
        </Box>
        <Divider/>
      </DialogTitle>
      <DialogContent sx={{ px: 4 }}>
      {
      (!!errors.find(e => e !== false)) ?
        <Box>
          <Typography variant='h6-regular'>
            Please check your network connection and try again. Otherwise, this section won't be evaluated.
          </Typography>
        </Box>
        :
        <Box style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <Typography variant='body01-bold'>
            Please stay on this and avoid refreshing to ensure your answers are saved. Thanks for your patience!
          </Typography>

          <Box width="100%" display={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Box flexGrow={1}>
              <LinearProgress
                color="success"
                variant={progress === 100 ? "indeterminate" : "determinate"}
                value={progress < 100 ? progress : undefined}
                sx={{
                  height: 24, border: '1px solid #00664A',
                  borderRadius: '4px',
                  backgroundColor: '#fff'
                }}
              />
            </Box>
            <Typography variant='body01-bold' color='success.clr-700'>
              {progress}%
            </Typography>
          </Box>
        </Box>
      }
      </DialogContent>
      {
        (!!errors.find(e => e !== false)) && 
        <DialogActions sx={{ px: 4, mb: 2, width: '500px' }}>
          <CustomButton variant="outlined" onClick={onSaved} sx={{ flexGrow: 1 }}>
            Submit
          </CustomButton>
          <CustomButton 
            variant="contained" 
            onClick={() => {
              setRetry(r => r + 1);
              setErrors([]);
              setAnalysed()
            }} 
            sx={{ flexGrow: 1 }}
          >
            Try again
          </CustomButton>
        </DialogActions>
      }
    </Dialog>
  );
}