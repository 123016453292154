import { Box, Typography } from '@mui/material';
import React from 'react';

import { makeStyles } from "@mui/styles";
import { sanitizeTableFIBData } from 'utils';
import { FIBInput } from 'components/FIB';
import SectionSlider from 'components/SectionSlider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", gap: "4px", 
    flexDirection: 'column', 
    height: "95%", 
  },
  leftSection: {
    height: '100%',
    overflow: 'auto',
  },
  rightSection: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2,0,2,2),
  },
  content: {
    display: 'flex',
    gap: '4px',
    minHeight: '-webkit-fill-available'
  },
  tableContainer: {
    overflowX: "auto",
    padding: "16px",
  },
  table: {
    maxWidth: '100%',
    border: "1px solid #ccc",
    borderCollapse: "collapse",
  },
  row: {
    border: "1px solid black",
    ...theme.typography['body01-medium']
  },
  cell: {
    padding: theme.spacing(1),
    border: "1px solid black", 
  },
}));

const Table = ({ data, questions, groupRange, updateQuestion}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  
  const handleChange = (e, i) => {
    const newValue = e.target.value;
    setValue(prev => {
      const updatedValues = [...prev];
      updatedValues[i] = newValue;
      return updatedValues;
    });
  
    updateQuestion(groupRange[0] - 1 + i, newValue)
  };

  React.useEffect(() => {
    setValue(() => {
      return questions.map((q) => q?.answer?.value || "");
    });
  }, [questions]);

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <tbody>
          {data.map((row, i) => (
            <tr key={i} className={classes.row}>
              {
                row.map((cell, index) => {
                  return (
                    <td key={index} className={classes.cell} style={{ fontWeight: cell?.bold ? 700 : 500 }}>
                      {cell?.content?.flat(Infinity).map((part, i) =>
                        typeof part === "object" && part !== null && !Array.isArray(part) ? (
                          <FIBInput
                            key={i}
                            index={groupRange[0] - 1 + part?.questionNo-1}
                            handleChange={(e)=>handleChange(e, part?.questionNo-1)}
                            handleInput={(e)=>{}}
                            value={value[part?.questionNo-1] }
                            defaultValue={value[part?.questionNo-1] }
                          />
                        ) : (
                          part
                        )
                      )}
                    </td>
                  );
                })
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableFIBSection = ({ groupRange, group, questions, updateQuestion }) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [leftWidth, setLeftWidth] = React.useState(50);

  React.useEffect(()=>{
    const sanitizedData = sanitizeTableFIBData(group.tables);
    setData(sanitizedData);
  }, [group]);

  return (
    <Box className={classes.root}>
      <Box>
        {group?.questionsRange && (
          <Typography variant="body01-bold" mb={1}>
            Questions {groupRange?.join("-")}
          </Typography>
        )}
      </Box>
      <Box className={classes.content}>
        {group?.passage && (
          <Box className={classes.leftSection} width={`${leftWidth}%`}>
            <Box>
              <Typography 
                variant="body01-bold" mb={2} 
                dangerouslySetInnerHTML={{ __html: group?.passage }} 
              />
            </Box>
          </Box>
        )}
        {
          group?.passage && 
            <SectionSlider setLeftWidth={setLeftWidth}/>
        }
        <Box
          className={classes.rightSection}
          style={{ width: group?.passage ? `${100 - leftWidth}%` : '100%'}}
        >
          <Box>
            {group?.description && (
              <Typography
                variant="body01-bold" mb={2} 
                dangerouslySetInnerHTML={{ __html: group?.description }} 
              />
            )}
          </Box>

          <Box className={classes.optionsRoot}>
            <Table 
              data={data} 
              questions={questions}
              groupRange={groupRange}
              updateQuestion={updateQuestion}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default TableFIBSection;