import Typography from '@mui/material/Typography';
import SpeechRecorder from 'languify-speech-recorder';
import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';

import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import PartDescription from 'components/PartDescription';
import RecordQuestion from './RecordQuestion';

import useStore from 'utils/ieltsStore';
import { Box } from '@mui/material';
import { AppShellNew } from '../SectionShell';
import { NoNetworkDialog } from 'dialogs/NetworkErrorDialog';
import { useConnected } from 'contexts';
import { playAudio } from 'utils';

const useStyles = makeStyles(theme=> ({
  root: {
    display: 'flex',
    alignItems: "center" ,
    gap: theme.spacing(2),
  },
  buttonRoot: {
    padding: '10px 0px',
    borderTop: '3px solid',
    borderColor: theme.palette.neutral['clr-200']
  },
  buttons: {
    padding: theme.spacing(1,2),
    border: '2px solid',
    borderColor: theme.palette.primary['clr-300'],
    borderRadius: '4px',
    cursor: 'pointer'
  },
  interviewSection: {
    width: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const PartsNavigation = () => {
  const classes = useStyles();
  const { questions = [], parts } = useStore(
    (state) => state.sections?.[state.section] || {}
  );
  const currentQuestion = useStore((state) => state.currentQuestion);
  const cueAudioPlayed = useStore(state => state.cueAudioPlayed);
  const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
  const setPartIndex = useStore((state) => state.setPartIndex);
  const isRecording = useStore((state) => state.isRecording);
  const questionRefs = useRef([]);

  const part = React.useMemo(() => 
    parts?.[questions?.[currentQuestion].part]
  , [questions, parts, currentQuestion]);

  // Find the current active part
  const activePart = questions?.[currentQuestion]?.part;

  const onSelect = (pIndex, qIndex) => {
    setPartIndex(pIndex);
    setCurrentQuestion(qIndex);
  };

  React.useEffect(() => {
    if (questionRefs.current[currentQuestion]) {
      questionRefs.current[currentQuestion].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentQuestion]);

  React.useEffect(() => {
      const partQuestions = questions.filter((ques) => ques.part === activePart);
      const totalAttempted = partQuestions.filter((ques) => ques?.answer?.submitted);
    
      if (partQuestions.length === totalAttempted.length) {
        part.completed = true;
      }
    }, [activePart, questions, part, currentQuestion]);

  return (
    <Box className={classes.root}>
      {questions?.map((q, j) => {
        const isSamePart = q.part === activePart;
        return (
          <React.Fragment key={j}>
            {/* Display Part Header */}
            {q.part !== questions?.[j - 1]?.part && (
              <Box
                padding="15px 20px"
                borderTop="3px solid"
                borderColor={parts[q.part]?.completed ? "#009970" : '#CCD4DE'}
                ml={4}
                mr={3}
                style={{ 
                  cursor: 'pointer', 
                  border: isSamePart && !cueAudioPlayed[questions[currentQuestion].part] && '2px solid #135EBF'
                }}
              >
                <Typography variant="body01-bold">
                  Part&nbsp;{q.part + 1}
                </Typography>
              </Box>
            )}

            {/* Hide questions that do not belong to the active part */}
            {isSamePart && cueAudioPlayed[questions[currentQuestion].part] && (
              <Box
                className={classes.buttonRoot}
                ref={(el) => (questionRefs.current[j] = el)}
                style={{
                  borderColor: q?.answer?.submitted ? "#009970" : "#8692A3",
                }}
              >
                <Box
                  key={j}
                  disabled={isRecording || currentQuestion !== j}
                  className={classes.buttons}
                  style={{ border: currentQuestion !== j && "none" }}
                  onClick={() =>
                    q.answer.submitted || currentQuestion !== j
                      ? {}
                      : onSelect(q.part, j)
                  }
                >
                  <Typography
                    variant="body01-bold"
                    color={currentQuestion === j ? "primary" : "#4E5661"}
                  >
                    {j + 1}
                  </Typography>
                </Box>
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

function SpeakingSection({ analyseAnswer, canFinish, beginInterview=()=>{} }) {
    const classes = useStyles();
    const [current, setCurrent] = React.useState(0);
    const {getConnectionStatus} = useConnected();
    const [openNetworkError, setOpenNetworkError] = React.useState(false);
    const endCueAudioRef = React.useRef(null);

    const { name, parts, questions, duration, groups } = useStore(
        state => state.sections[state.section]
    );
    const currentQuestion = useStore(state => state.currentQuestion);
    const sections = useStore(state => state.sections);
    const section = useStore(state => state.section);

    const question = React.useMemo(
        () => questions?.[currentQuestion], [currentQuestion, questions]
    );
    const partIndex = React.useMemo(() => question.part, [question]);
    const part = React.useMemo(() => parts?.[question.part], [question, parts]);

    const speechRecorder = React.useRef(null);

    const handleNext = () => {
      if(current===0) beginInterview();
      if (current < 3) {
          setCurrent((x) => x + 1);
      }
    }

    const handleAnalyseAnswer = async (answer) => {
      try{
        const status = await getConnectionStatus();
        if(status){
          if(currentQuestion===questions.length-1 && group?.endCueAudio){
            endCueAudioRef.current = playAudio(group?.endCueAudio || null, (e) => {
              console.log("error playing audio")
            });
            
            endCueAudioRef.current.addEventListener('ended', ()=>{analyseAnswer(answer)});
          }else{
            analyseAnswer(answer);
          }
        }else{
          setOpenNetworkError(true);
        }
      }catch(error){
        console.log("error---",error);
      }
    }

    /**
     * Effect to initialize SpeechRecorder
     */
    React.useEffect(() => {
        if (name.toUpperCase() === 'SPEAKING') {
            speechRecorder.current = new SpeechRecorder({ isMobile: false });

            return () => speechRecorder.current?.destructor();
        }
    }, [name]);

    const group = React.useMemo(
      () => groups?.[question.group], [question, groups]
    );

    return (
      <AppShellNew
        active
        timerProps={{
            hide: current===0 ? true : false,
            totalTime: current===0 ? null : sections[section]?.duration,
            onTimeout: handleNext
        }}
        footerProps={{
            hideNav: true,
            hide: current !== 1,
            children: (current === 1) ? <PartsNavigation /> : null,
        }}
      >

        <SectionInstructions
            type="ielts"
            active={current === 0}
            name="speaking"
            onNext={handleNext}
            time={duration}
            timer={300}
            sections={sections}
        />

        {current === 1 && (
            <Box className={classes.interviewSection}>
                <PartDescription
                    part={partIndex + 1}
                    content={part.description}
                />
                <RecordQuestion
                    active={current === 1}
                    speechRecorder={speechRecorder}
                    analyseAnswer={handleAnalyseAnswer}
                    question={question}
                    image={group?.image}
                />
            </Box>
        )}

        <NoNetworkDialog
          buttonLabel={'Resume test'}
          isOpen={openNetworkError}
          onSubmit={()=>{
            setOpenNetworkError(false);
            handleAnalyseAnswer(question.answer);
          }}
        />
      </AppShellNew>
    );
}

export default SpeakingSection;