export const IELTSLevels = {
    9: {
        label: 'Expert user',
        description: 'The test taker has full command of the language, with complete accuracy, fluency, and understanding.'
    },
    8: {
        label: 'Very good user',
        description: 'The test taker has fully operational command of the language with only occasional unsystematic inaccuracies. They may misunderstand some things in unfamiliar situations but handle complex detailed argumentation well.'
    },
    7: {
        label: 'Good user',
        description: 'The test taker has operational command of the language, though occasional inaccuracies, inappropriate usage, and misunderstandings may occur in some situations. They generally handle complex language well and understand detailed reasoning.'
    },
    6: {
        label: 'Competent user',
        description: 'The test taker has an effective command of the language despite some inaccuracies and misunderstandings. They can use and understand fairly complex language, particularly in familiar situations.'
    },
    5: {
        label: 'Modest user',
        description: 'The test taker has partial command of the language and copes with overall meaning in most situations, although they are likely to make many mistakes. They should be able to handle basic communication.'
    },
    4: {
        label: 'Limited user',
        description: 'The test takers basic competence is restricted to familiar situations. They frequently have problems with understanding and expression. They are unable to use complex language.'
    },
    3: {
        label: 'Extremely limited user',
        description: 'The test taker conveys and understands only general meaning in very familiar situations. There are frequent communication breakdowns.'
    },
    2: {
        label: 'Intermittent user',
        description: 'The test taker has significant difficulty understanding spoken and written English.'
    },
    1: {
        label: 'Non-user',
        description: 'The test taker has no ability to use the language except for a few isolated words.'
    },
    0: {
        label: 'Did not attempt the test',
        description: 'The test taker did not answer the questions.'
    }
};

export const Levels = {
    EXPERT: {
        label: 'Expert',
        color: '#41D7A1',
        backgroundColor: '#EDFFF7',
    },
    INTERMEDIATE: {
        label: 'Intermediate',
        color: '#FFCC00',
        backgroundColor: '#FFF9ED',
    },
    BEGINNER: {
        label: 'Beginner',
        color: '#EE2D2D',
        backgroundColor: '#FFF0F0',
    },
};

export const Sections = {
    "LISTENING": {
        label: 'Listening',
        title: 'Detailed Analysis for the Listening Comprehension',
        tipTitle: 'Customised tips for the listening comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "Take Notes Effectively: You may take notes while listening and use them to assist in answering the questions. This can help you retain key information and structure your responses accurately.",
            "Ensure Grammatical Accuracy: For fill-in-the-gap questions, ensure that the completed sentence is grammatically correct. Incorrect grammar may lead to losing marks, even if the word choice is correct.",
            "Pay Attention to Singular and Plural Forms: A frequent mistake in the IELTS Listening test is not recognising whether a word should be in singular or plural form. Listen carefully to ensure accuracy.",
            "Use Exact Words from the Recording: In most cases, you are expected to write down the precise word(s) as spoken in the audio. Avoid paraphrasing unless instructed otherwise.",
            "Read and Understand the Questions Thoroughly: Carefully read the instructions and questions before listening. Ensure you answer all required points, as missing key details could result in lost marks."
        ],
    },
    "READING": {
        label: 'Reading',
        title: 'Detailed Analysis for the Reading Comprehension',
        tipTitle: 'Customised tips for the reading comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "Manage Your Time Wisely: You may skip difficult questions and return to them later, provided time allows. Prioritising easier questions first can help maximise your score.",
            "Identify Irrelevant Sentences in Summaries: Some sentences in summary completion tasks may not belong because they include minor details or information not presented in the passage. Be selective in your choices.",
            "Utilise Skimming and Scanning Techniques: Skimming helps you grasp the main idea of the passage quickly, while scanning allows you to locate specific information efficiently. Using both strategies can save time.",
            "Focus on Keywords: Pay attention to keywords in both the questions and the passage. Identifying synonyms and paraphrased expressions will help in locating the correct answers.",
        ],
    },
    "WRITING": {
        label: 'Writing',
        title: 'Detailed Analysis for Writing Assesment',
        tipTitle: 'Customised tips for the writing comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "Refer Back to the Question Prompt: You can revisit the task prompt while writing to ensure your response remains relevant and well-structured. Use this to refine your answer and meet the expectations of the task.",
            "Take Notes while Reading and analysing: Jotting down key points as you read or analyse can help organise your ideas and structure your response more effectively.",
            "Meet the Word Count Requirement: A well-developed response should typically contain at least 250 words for Task 2 and 150 words for Task 1. Writing fewer words may result in a lower score.",
        ],
        parameters: {
            'grammar': 'Grammar',
            'vocabulary': 'Vocabulary',
            'spelling': 'Spelling',
            'phraseRepetition': 'Phrase Repetition',
            'slangs': 'Slangs',
        },
        tags: {
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'overUsedWords': 'Over used words'
        }
    },
    "SPEAKING": {
        label: 'Speaking',
        title: 'Detailed Analysis for Speaking Assesment',
        tipTitle: 'Customised tips for the speaking comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "Listen Carefully to the Questions: Paying close attention to the examiner’s questions will help you understand the context better and respond appropriately.",
            "Take Notes to Structure Your Response: If preparing for Part 2 (Cue Card), jotting down key points can help you organise your thoughts and deliver a well-structured answer.",
            "Speak Clearly and Confidently: Avoid fumbling by speaking at a steady pace. Pronounce words clearly and ensure your speech is loud enough to be understood.",
            "Use a Natural and Simple Accent: There is no need to adopt a foreign accent. Focus on clear pronunciation and fluency rather than trying to imitate a particular accent."
        ],
        parameters: {
            'fluency': 'Fluency',
            'delivery': 'Delivery',
            'vocabulary': 'Vocabulary',
            'pauses': 'Pauses',
            'phraseRepeatRating': 'Phrase Repetition',
        },
        tags: {
            "fillerPhrases": "Filler Phrases",
            'fillerWords': 'Filler Words',
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'speechRate': 'Speech rate',
            'breakdown': 'Breakdown'
        }
    }
}

export const Glossaries = [
    {
        word: 'Dylexic Ability',
        meaning: 'The ability to produce and understand the words of a language. Lexical competence is an aspect of both linguistic competence and communicative competence.'
    },
    {
        word: 'Conciseness',
        meaning: 'The quality of being short and clear, and expressing what needs to be said without unnecessary words'
    },
    {
        word: 'Punctuation',
        meaning: 'In simple terms, punctuation marks are a symbol to create and support meaning within a sentence or to break it up.'
    },
    {
        word: 'Grammar',
        meaning: 'the whole system and structure of a language or of languages in general, usually taken as consisting of syntax and morphology (including inflections) and sometimes also phonology and semantics.'
    },
    {
        word: 'Vocabulary',
        meaning: 'Vocabulary is the all the language and words either used or understood by a person or group of people.'
    },
    {
        word: 'Coherence',
        meaning: 'The definition of coherence is something logical or consistent and something that makes sense as a whole.'
    },
];