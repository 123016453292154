export const checkPermission = async (name) => {
    return await new Promise((resolve, reject) => {
        if (navigator?.permissions && navigator?.permissions?.query) {
            navigator.permissions.query(
                { name }
            ).then(function (permissionStatus) {
                if (permissionStatus.state === "granted") resolve(true);
                else resolve(false);
            }).catch(err => {
                console.error(err);
                resolve(false);
            });
        } else {
            askPermission({ video: false, audio: true })
                .then(resolve)
                .catch(reject);
        }
    });
};

export const checkPermissionState = async (name, onChange) => {
  return new Promise((resolve, reject) => {
    if (navigator?.permissions && navigator?.permissions?.query) {
      navigator.permissions.query({ name })
        .then((permissionStatus) => {
          resolve(permissionStatus.state);

          permissionStatus.onchange = () => {
            onChange?.(permissionStatus.state);
          };
        })
        .catch((err) => {
          console.error(err);
          resolve("denied");
        });
    } else {
      askPermission({ video: false, audio: true })
        .then((isGranted) => resolve(isGranted ? "granted" : "denied"))
        .catch(reject);
    }
  });
};


export const askPermission = async (permission) => {
    return await new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia(permission).then(stream => {
            resolve(true);
        }).catch(err => {
            console.error(err);
            resolve(false);
        });
    });
}

export const fetchMediaDevices = async (onDevicesFetched, onDeviceSelected) => {
  try {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const audioInputs = allDevices.filter((device) => device.kind === 'audioinput');

    onDevicesFetched?.(audioInputs);

    if (audioInputs.length > 0) {
      onDeviceSelected?.(audioInputs[0].label);
    }
  } catch (error) {
    console.error('Error fetching devices:', error);
  }
};