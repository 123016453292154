import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, Divider, IconButton, Typography } from '@mui/material';

import DialogTransition from 'components/DialogTransition';
import useStore from 'utils/ieltsStore';
import CustomButton from 'components/CustomButton';
import { useConnected } from 'contexts';
import { NoNetworkDialog } from './NetworkErrorDialog';

export default function SubmitSectionDialog({ section, onSubmit }) {
  const isOpen = useStore(state => state.submitSectionDialog);
  const close = useStore(state => state.closeSubmitSectionDialog);
  const {connected} = useConnected();
  const [openNetworkError, setOpenNetworkError] = React.useState(false);

  React.useEffect(()=>{
    if(!connected && isOpen) setOpenNetworkError(true);
  }, [connected, isOpen]);

  return openNetworkError ? 
    (
      <NoNetworkDialog
        isOpen={openNetworkError}
        onClose={()=>{
          setOpenNetworkError(false);
          close();
        }}
        onSubmit={()=>{
          setOpenNetworkError(false);
          onSubmit();
          }
        }
      />
    )
    :
    (
      <Dialog
        keepMounted open={isOpen} TransitionComponent={DialogTransition}
        onClose={close} maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ m: 0, px: 4 }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5-medium'>
              You are submitting <span style={{ textTransform: 'capitalize' }}>{section?.toLowerCase()}</span> section!&nbsp;&nbsp;
            </Typography>
            <IconButton onClick={close}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <Divider/>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography variant='body01-bold'>
            You will not be able to reattempt this section.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 4, mb: 2 }}>
          <CustomButton variant="outlined" onClick={onSubmit} sx={{ flexGrow: 1 }} disabled={!connected}>
            Submit
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
}