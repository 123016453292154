import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FIB from 'components/FIB';
import PartDescription from 'components/PartDescription';
import { PartsNavigation } from 'components/IELTSMock/Reading';
import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import SCQ from 'components/SCQ';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import { makeStyles } from '@mui/styles';

import useStore from 'utils/ieltsStore';
import { useConnected, useSnackbar } from 'contexts';
import MarkParaGroup from 'components/MarkParaGroup';
import MCQ from 'components/MCQ';
import { AppShellNew } from '../SectionShell';
import PlayAudioDialog from 'dialogs/PlayAudioDialog';
import ParaDND from 'components/ParaDND';
import TickMarkSection from 'components/TickMarkSection';
import { playOrStopAudio } from 'utils/audio';
import TableFIBSection from '../TableFIBSection';

const useStyles = makeStyles(theme=>({
  interviewSection: {
    width: '100%',
    height: '100%',
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

function ListeningSection({ onTimeout, beginInterview=()=>{} }) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const {connected} = useConnected();

  const [current, setCurrent] = React.useState(0);
  const audioPlayed = React.useRef(new Set());

  const sections = useStore(state => state.sections);
  const section = useStore(state => state.section);
  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(
    () => groups?.[question.group], [question, groups]
  );

  const [openPlayAudioDialog, setOpenPlayAudioDialog] = React.useState(true);

  const handleNext = () =>{
    if(current===0) beginInterview();
    setCurrent((x) => x + 1);
  } 

  React.useEffect(() => {
    if (!audioPlayed.current.has(partIndex) && current === 2) {
      if (connected) {
        audioPlayed.current.add(partIndex);

        playOrStopAudio({ stop: true }) // Stop any existing audio first
          .then(() => playOrStopAudio({ source: part?.audio || null }))
          .catch((error) => snackbar.error(error.message));

        return () => {
          playOrStopAudio({ stop: true }).catch(() => {});
        };
      } else {
        snackbar.error("Unable to play audio! Check your network connection");
      }
    }
  }, [partIndex, part, current, connected]);

  const footerProps = React.useMemo(() => ({
    hide: current !== 1,
    children: <PartsNavigation />,
    disableNext: currentQuestion + 1 === questions?.length,
    disablePrev: currentQuestion === 0,
  }), [current, currentQuestion, questions]);

  const partRange = part?.questionsRange?.join("-");
  const groupRange = group?.questionsRange;

  const groupQuestions = questions?.slice(groupRange[0] - 1, groupRange[1]) || [];

  const handlePlayAudio = () => {
    setOpenPlayAudioDialog(false);
    try{
      playOrStopAudio({source: part?.audio});
    }catch (error) {
      snackbar.error(error.message);
    }
  }

  const updateQuestion = React.useCallback((index, newValue) => {
    questions[index].answer.value = newValue;
    return questions[index].answer.value;
  },[questions]);

  return (
    <AppShellNew
      active
      headerProps={{ hideFinish: current !== 1 }}
      footerProps={footerProps}
      timerProps={{
        totalTime: current===0 ? time[current] : sections[section]?.duration,
        onTimeout: current !== 1 ? handleNext : onTimeout
      }}
    >
      <SectionInstructions
        type="ielts"
        active={current === 0}
        name='listening'
        time={duration}
        onNext={handleNext}
      />
      {current === 1 && (
        <Box className={classes.interviewSection}>
          <PartDescription
            part={partIndex + 1}
            content={`${part.description} ${partRange}`}
          />
          <QuestionsContainer>
            {
              ['para_dnd', 'img_dnd'].includes(group?.type) ?
                <ParaDND 
                  questions={groupQuestions} 
                  group={group} 
                  groupRange={groupRange}
                  updateQuestion={updateQuestion}
                />
                :
                group?.type === 'table_fib' ?
                  <TableFIBSection
                    questions={groupQuestions}
                    group={group}
                    currentQuestion={currentQuestion}
                    groupRange={groupRange}
                    updateQuestion={updateQuestion}
                  />
                :
                group?.type === 'tick_mark' ?
                  <TickMarkSection
                    questions={groupQuestions}
                    group={group}
                    currentQuestion={currentQuestion}
                    groupRange={groupRange}
                  /> 
                :
                <Box>
                  {group?.questionsRange && (
                    <Typography variant='body01-bold' mb={1}>
                      Questions {groupRange?.join("-")}
                    </Typography>
                  )}

                  {group?.description && (
                    <Typography
                      variant='body01-bold' mb={2} dangerouslySetInnerHTML={{
                        __html: group?.description,
                      }}
                    />
                  )}

                  {group?.image && (
                    <img
                      alt="Part"
                      src={group?.image}
                      width="100%"
                    />
                  )}
                </Box>
            }

            {group?.type === 'mcq' && (
              groupQuestions?.map((question, i) => (i % 2 === 0) ? (
                <Box display="flex" width="100%" key={i} columnGap={2}>
                  <MCQ
                    active
                    index={groupRange[0] + i - 1}
                    currentQuestion={currentQuestion}
                    question={question}
                    width="50%"
                  />
                  {(i + 1 < groupQuestions?.length) && (
                    <MCQ
                      active
                      index={groupRange[0] + i}
                      currentQuestion={currentQuestion}
                      question={groupQuestions?.[i + 1]}
                      width="50%"
                    />)}
                </Box>
              ) : null)
            )}

            {group?.type === 'scq' && (
              groupQuestions?.map((question, i) => (i % 2 === 0) ? (
                <Box display="flex" width="100%" key={i} columnGap={2}>
                  <SCQ
                    active
                    alwaysOpen
                    index={groupRange[0] - 1 + i}
                    width="50%"
                    currentQuestion={currentQuestion}
                    question={question}
                  />
                  {(i + 1 < groupQuestions?.length) && (
                    <SCQ
                      active
                      alwaysOpen
                      index={groupRange[0] + i}
                      width="50%"
                      currentQuestion={currentQuestion}
                      question={groupQuestions?.[i + 1]}
                    />)}
                </Box>
              ) : null)
            )}

            {group?.type === 'fib' && (
              <Box display="flex" width="100%" columnGap={2}>
                {(group?.image || group?.content) && (
                  <Box width="50%" border="1px solid grey" m={2}>
                    {group?.content && (
                      <Box
                        width="100%"
                        m={2} dangerouslySetInnerHTML={{
                          __html: group?.content || ""
                        }}
                      />
                    )}

                    {group?.image && (
                      <img
                        alt="Part"
                        src={group?.image}
                        width="100%"
                      />
                    )}
                  </Box>
                )}

                <Box m={2}>
                  {groupQuestions?.map((question, i) => (
                    <FIB
                      key={i}
                      index={groupRange[0] - 1 + i}
                      question={question}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {group?.type === "mark_para" && (
              <MarkParaGroup
                currentQuestion={currentQuestion}
                group={group}
                groupRange={groupRange}
                groupQuestions={groupQuestions}
              />
            )}
          </QuestionsContainer>
        </Box>
      )}
      <PlayAudioDialog
        open={current===1 && openPlayAudioDialog}
        onSubmit={handlePlayAudio}
      />
    </AppShellNew>
  );
}

export default ListeningSection;