import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme=>({
  root: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 5)
  },
}));

function Passage({ active, noScroll, children, ...props }) {
  const classes = useStyles();

  return active ? (
    <Box {...props}>
      <Box className={classes.root}>
        <Typography
          variant="body01-medium"
          maxHeight={(noScroll) ? '100% !important' : ''}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </Box>
    </Box>
  ) : null;
}

export default Passage;