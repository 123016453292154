import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";

const useStyles = makeStyles((theme) => ({
    cubeContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cube: {
        width: 20,
        height: 20,
        borderRadius: 4,
        fontSize: '12px',
        fontWeight: 600,
        cursor: 'pointer',
        transition: 'backgroundColor 2s ease-in-out',
        ...theme.typography['overline-semiBold']
    }
}));

export default function PartWithAnswers(
    { index, questions = [], onAnswerClick, ...props }
) {
    const partIndex = index;

    const classes = useStyles();
    const section = useStore((state) => state.section);
    const answers = useStore((state) => state.analysis[section]);
    const currentQuestion = useStore((state) => state.currentQuestion);
    const currentPartIndex = useStore((state) => state.partIndex);

    const handleClick = (index) => onAnswerClick(index, partIndex);

    return (
        <Box className={classes.cubeContainer} {...props}>
            <Typography variant="body02-bold" my={1}>
                Part {partIndex + 1}
            </Typography>
            <Box display='flex' flexWrap='wrap' gap={2}>
                {questions.map((question, index) => {
                    const answer = answers?.[index];
                    const score = answer?.analysis?.score;
                    const correct = answer && score && score !== 0;
                    const active = (
                        currentQuestion === index &&
                        currentPartIndex === partIndex
                    );
                    const color = correct ? '#41D7A1' : '#E98862';
                    return (question.part === partIndex) ? (
                        <CenterFlexBox
                            key={index}
                            className={classes.cube}
                            color={active ? '#ffffff' : color}
                            border={`1.5px solid ${color}`}
                            backgroundColor={active ? color : 'transparent'}
                            onClick={() => handleClick(index)}
                        >
                            {index + 1 || -1}
                        </CenterFlexBox>) :
                        null
                })}
            </Box>
        </Box>
    )
}