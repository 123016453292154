import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel({ percent, bgcolor, ...props }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props} sx={{ color: bgcolor || "red" }}
                value={Math.round((props.value / 9) * 100)}

            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="h4-semiBold"
                    component="div"
                    color={bgcolor || "red"}
                >
                    {`${Math.round(props.value)}`}
                    <span style={{ fontSize: "15px" }}>{percent && "%"}</span>
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircleProgressbar({ value, section, percent, color, ...props }) {

    return <CircularProgressWithLabel
        value={value || 0}
        bgcolor={color}
        style={{
            backgroundColor: (color || '#737373') + "2e",
            borderRadius: "50%",
        }}
        thickness={2}
        size={180}
        percent={percent}
        {...props}
    />;
}
