import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme=>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100vh",
    gap: theme.spacing(2)
  }
}));

function NotFound() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h6-semiBold'>
        The page you are looking for is not found
      </Typography>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h4-bold'>
          Error
        </Typography>
        <Typography
          variant='h1-medium'
          color='#D8D8D8'
          fontSize={300}
        >
          404
        </Typography>
      </Box>
    </Box>
  );
}

export default NotFound;
