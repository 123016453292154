import { round } from "utils";

export function subscribeToAudioLevel(stream, listener) {
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    const audioContext = new AudioContext();

    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        listener(round(average));
    };

    return () => {
        scriptProcessor.disconnect();
        audioContext.close().then(() => {
            stream.getTracks().forEach(track => track.stop());
        }).catch(err => console.error('Error closing AudioContext:', err));
    };
}

let audioInstance = null;

export const playOrStopAudio = ({ source, stop = false } = {}) => {
  return new Promise((resolve, reject) => {
    try {
      // Stop any currently playing audio
      if (stop && audioInstance) {
        audioInstance.pause();
        audioInstance.currentTime = 0;
        audioInstance = null;
        resolve();
        return;
      }

      if (!source) {
        reject(new Error("Invalid audio source"));
        return;
      }

      // Stop existing audio before playing new one
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.currentTime = 0;
      }

      let audioUrl;

      // Check if the source is a Blob or URL
      if (source instanceof Blob) {
        audioUrl = URL.createObjectURL(source);
      } else if (typeof source === "string") {
        audioUrl = source;
      } else {
        reject(new Error("Invalid audio source type"));
        return;
      }

      audioInstance = new Audio(audioUrl);
      audioInstance.play()
        .then(()=> resolve(audioInstance))
        .catch(() => reject(new Error("Unable to play audio! Check your network connection")));
    } catch (error) {
      reject(error);
    }
  });
};