import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import CustomButton from 'components/CustomButton';
import SpeakerTest from 'components/SpeakerTest';
import MicrophoneTroubleshoot from 'components/MicrophoneTroubleshoot';
import { AppShellNew } from 'components/IELTSMock/SectionShell';
import useStore from 'utils/ieltsStore';
import { playOrStopAudio } from 'utils/audio';
import { useSnackbar } from 'contexts';

const useStyles = makeStyles(theme=>({
  root: {
    boxShadow: '0px 2px 8px 0px #00000029',
    height: '550px',
    width: '75%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: '20px',
    flexGrow: 1,
    overflow: 'auto',
  },
  tabs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(8),
  },
  tab: {
    width: '50%',
  },
  tabBorder: {
    height: '4px',
    marginTop: theme.spacing(1),
    borderRadius: '2px',
    backgroundColor: theme.palette.neutral['clr-200'],
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '80px',
    alignItems: 'center',
    boxShadow: '0px -8px 32px 0px #00000029',
    padding: theme.spacing(0,4)
  }
}));

const HardwareCheck = ({ proceed=()=>{} }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const sections = useStore(state => state.sections);
  
  const [availableTest, setAvailableTest] = React.useState([false, false]); // [speaker, microphone]
  const [testSuccess, setTestSuccess] = React.useState([false, false]); // [speaker, microphone]
  const [step, setStep] = React.useState(0); // 0-> speaker test, 1-> microphone test

  React.useEffect(()=>{
    const sectionNames = sections.map(section => section.name);
    const speakerAvailable = sectionNames.includes('Listening') || sectionNames.includes('Speaking');
    const microphoneAvailable = sectionNames.includes('Speaking');
    
    setAvailableTest([speakerAvailable, microphoneAvailable]);
    setStep(speakerAvailable ? 0 : 1);
  },[sections]);

  const handleNext = () => {
    try{
      playOrStopAudio({stop: true});
    }catch (error) {
      snackbar.error(error.message);
    }
    if(availableTest[step+1]){
      setStep(step+1);
    }
    else{
      proceed();
    }
  }

  return (
    <AppShellNew>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.header}>
            <Typography variant='h5-semiBold'>Quick hardware check</Typography>
            <Typography variant='body01-bold' color='neutral.clr-600'>
              Help us delivering a seamless experience for you
            </Typography>
          </Box>

          <Divider style={{ borderColor: '#8692A3'}}/>

          <Box className={classes.tabs}>
            {availableTest.map((isAvailable, index) =>
              isAvailable ? (
                <Box key={index} className={classes.tab}>
                  <Typography variant='h6-semiBold'>
                    {index === 0 ? 'Speaker Test' : 'Microphone Test'}
                  </Typography>
                  <Box
                    className={classes.tabBorder}
                    style={{ 
                      backgroundColor: (step===1 && testSuccess[1]) 
                          ? '#009970' 
                          : (step===1 && testSuccess[index]) && '#009970' 
                    }}
                  />
                </Box>
              ) : null
            )}
          </Box>

          {
            availableTest[0] &&
              <SpeakerTest 
                handleSuccess={(status)=>{ setTestSuccess([status, testSuccess[1]]) }}
                active={step===0}
              />
          }
          {
            availableTest[1] && step === 1 &&
              <MicrophoneTroubleshoot
                handleSuccess={(status)=>{ setTestSuccess([testSuccess[0], status]) }}
              />
          }
        </Box>

        <Box className={classes.footer}>
          <Box>
            {
              testSuccess[step] && step!==0 ? null :
                <Box style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  <ErrorOutlineOutlinedIcon style={{ color: '#BF7900' }}/>
                  <Typography variant='h6-medium' color='warning.main'>
                  {
                    step === 0
                      ? 'Ensure you can hear the sound before proceeding!'
                      : "You can proceed once your microphone has been successfully tested!"
                  }
                  </Typography>
                </Box>
            }
          </Box>
          <CustomButton size='medium' disabled={!testSuccess[step]} onClick={handleNext}>
            Proceed
          </CustomButton>
        </Box>
      </Box>
    </AppShellNew>
  );
}

export default HardwareCheck;