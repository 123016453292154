import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import { Table } from "components/Table";
import Title from "../Title";
import { Glossaries, IELTSLevels } from "./constants";

function Glossary() {
    return (
        <Box >
            <Box
                backgroundColor='#02569D'
                color='#ffffff'
                borderRadius='6px'
                py={1.5} pl={2}
            >
                <Typography variant="body01-bold">
                    Report Glossary
                </Typography>
            </Box>
            {
                Glossaries.map((glossary, index) => (
                    <Box key={index}>
                        <CenterFlexBox
                            justifyContent='space-between'
                            alignItems='flex-start'
                            my={4}
                        >
                            <Typography variant="body01-bold" ml={2} width='40%'>
                                {glossary.word}
                            </Typography>
                            <Typography
                                variant="body01-medium" color='rgba(0, 0, 0, 0.6)' width='60%'
                            >
                                {glossary.meaning}
                            </Typography>
                        </CenterFlexBox>
                        <Divider variant="fullWidth" />
                    </Box>
                ))
            }
        </Box>
    )
}

function BandDescriptor() {

    const rows = Object.keys(IELTSLevels).map((key) => (
        {
            score: key,
            level: IELTSLevels[key].label,
            description: IELTSLevels[key].description
        }));

    return (
        <>
            <Title title='IELTS Band Descriptor' /> <br />
            <Table
                columns={['Band Score', 'Skill level', 'Description']}
                rows={rows}
            />
        </>
    );
}

export default function Descriptors({ active }) {

    return (
        (active) ? (
            <>
                <BandDescriptor />
                <br /> <br />
                {/* <Glossary /> */}
            </>
        ) : null
    );
}