import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Session from "utils/Session";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorBox: {
    height: '250px',
    width: '550px',
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #CCD4DE',
    borderRadius: '8px',
    backgroundColor: theme.palette.success['clr-100'],
    gap: theme.spacing(2)
  }
}));


function SubmitSuccess({
  message = 'Submission Successful!', closeTime = 5
}) {
  const classes = useStyles();
  const [countdown, setCountdown] = React.useState(closeTime);

  React.useEffect(() => {
    if (!Session.redirectUrl) return;

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          window.open(Session.redirectUrl, "_top");
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [Session]);

  return (
    <Box className={classes.root}>
      <Box className={classes.errorBox}>
        <img src="https://assets.languify.in/images/success-icon.svg" alt="success" style={{ marginBottom: '10px' }} />
        <Typography variant="h5-medium">
          {message}
        </Typography>
        {
          Session.redirectUrl ?
            <Typography variant="body01-semiBold">
              Closing in {countdown} seconds...
            </Typography>
            : null
        }
      </Box>
    </Box>
  );
};

export default SubmitSuccess;