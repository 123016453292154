import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";

const sx = {
    profileContainer: {
        border: "0.5px solid #02569D",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px",
        margin: "40px 0px"
    },
    label: {
        fontSize: "12px",
        fontWeight: 500,
        color: '#626060'
    }
};

export default function Details({ active }) {

    const details = useStore((state) => state.details);

    return (
        active ? (
            <Box sx={sx.profileContainer}>
                <Box display='flex' flexDirection='column'>
                    <Typography variant="h6-medium">
                        {details?.name}
                    </Typography>
                    <Typography variant="body02-medium">
                        {details?.email}
                    </Typography>
                </Box>

                <CenterFlexBox flexDirection='column' alignItems='flex-start' gap={1}>
                    <Typography variant="body02-medium" >
                        Test type : {details?.testType}
                    </Typography>
                    <Typography variant="body02-medium" >
                        Date : {details?.date}
                    </Typography>
                </CenterFlexBox>
            </Box>
        ) : null
    );
}