import React from "react";
import Box from "@mui/material/Box";
import useStore from "utils/reportStore";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Passage from "components/Passage";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    title: {
        fontFamily: "inter",
        fontSize: 18,
        fontWeight: 600,
    },
    container: {
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    overlay: {
        position: "absolute",
        width: "300px",
        height: "100%",
        zIndex: 1,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderRadius: 24,
        WebkitBackdropFilter: "blur(1px)",
        backdropFilter: "blur(1px)",
        textAlign: "center",
        paddingTop: 15,
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "inter",
        alignSelf: "center",
        color: "#ffffff",
    },
    content: {
        fontFamily: "inter",
        fontSize: 14,
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.6)",
    }
});

function AudioElement({ audio }) {

    const Audio = React.useCallback(() => {
        return (<audio controls>
            <source src={audio}></source>
        </audio>)
    }, [audio]);

    return <Audio />;
}

function AudioAnswer({ questionAudio, yourAnswerAudio, idealAnswerAudio }) {
    const classes = useStyles();

    return (
        <>
            <br /><br />
            <Typography variant="h6-medium">
                Question Audio:
            </Typography>
            <Box className={classes.container} marginTop={2} >
                <AudioElement audio={questionAudio} />
            </Box> <br /><br />
            {/* <Typography mb={1} className={classes.title}>
                Your Answer Audio:
            </Typography>
            <Box display='flex' gap='4px'>
                <ErrorOutlineIcon />
                <Typography>Under development</Typography>
            </Box> */}
            {/* <Box className={classes.container} >
                <Box className={classes.overlay}>Under development</Box>
                <AudioElement audio={yourAnswerAudio} />
            </Box> */}
            <br /><br />
            {/* <Typography mb={1} className={classes.title}>
                Ideal Answer Audio:
            </Typography>

            <Box display='flex' gap='4px'>
                <ErrorOutlineIcon />
                <Typography>Under development</Typography>
            </Box> */}

            {/* <Box className={classes.container} >
                <Box className={classes.overlay}>Under development</Box>
                <AudioElement audio={idealAnswerAudio} />
            </Box> */}
        </>
    );
}

export default function Questions({ active }) {
    const {
        parts = [], groups = [], questions = [], answers = [],
    } = useStore((state) => state.sections?.[state.section] || {});
    const analyses = useStore((state) => state.analysis[state.section]);
    const currentQuestion = useStore((state) => state.currentQuestion);
    const partIndex = useStore((state) => state.partIndex);
    const type = useStore((state) => state.sections[state.section]?.name?.toUpperCase());

    const question = questions?.[currentQuestion];
    const group = groups?.[question?.group];
    console.log({ question, analyses });


    return (
        (active) ? (
            <>
                <Typography
                    variant="h5-medium"
                    color="#02569D"
                >
                    PART {partIndex !== null && (partIndex + 1)} |
                    Q{currentQuestion !== null && (currentQuestion + 1)}
                </Typography>

                {type === "SPEAKING" && (
                    <>
                        {
                            (question.audio) ?
                                <AudioAnswer
                                    questionAudio={question?.audio}
                                /> :
                                <Typography
                                    variant="body01-medium"
                                    dangerouslySetInnerHTML={{
                                        __html: group?.description || "NA"
                                    }}
                                >
                                </Typography>
                        }
                        <Box display='flex' flexDirection='column' gap={3}>
                            <Typography variant="h6-medium">
                                Transcript :
                            </Typography>
                            <Typography variant="body01-medium">
                                {analyses?.[currentQuestion]?.answer || "NA"}
                            </Typography>
                        </Box>

                    </>
                )}
                {type === "WRITING" && (<>
                    {(question?.content) && (
                        <Typography
                            fontFamily="inter"
                            fontSize={12}
                            fontWeight={500}
                            color="rgba(0, 0, 0, 0.6)"
                            dangerouslySetInnerHTML={{
                                __html: question?.content || "NA"
                            }}
                        >
                        </Typography>
                    )}
                    <br />
                    {(group?.image) && (<>
                        <img
                            src={group.image}
                            alt="diagram"
                            width={"100%"}
                            height={"auto"}
                        />
                        <br />
                        <br />
                    </>)}

                    <Typography
                        fontFamily="inter" fontSize={15} fontWeight={600}
                    >
                        Your answer:
                    </Typography>
                    <Typography
                        fontFamily="inter" fontSize={12} fontWeight={500}
                        dangerouslySetInnerHTML={{
                            __html: analyses?.[currentQuestion]?.answer || "NA"
                        }}
                    >
                    </Typography> <br />
                    {answers?.[currentQuestion] && (<>
                        <Typography
                            fontFamily="inter"
                            fontSize={15}
                            fontWeight={600}
                            color="green"
                        >
                            Ideal answer:
                        </Typography>

                        <Typography
                            fontFamily="inter"
                            fontSize={12}
                            fontWeight={400}
                            dangerouslySetInnerHTML={{
                                __html: answers?.[currentQuestion] || "NA"
                            }}
                        >
                        </Typography>
                    </>)}
                    <br />
                </>)
                }
                {(type === "READING") && (<>
                    <br />
                    <Typography
                        fontFamily="inter"
                        fontSize={15}
                        fontWeight={600}
                    >
                        Passage:
                    </Typography>
                    <Passage active noScroll>
                        {group?.passage || "NA"}
                    </Passage>
                    <br />

                    {(group?.content) && (<>
                        <Typography
                            fontFamily="inter"
                            fontSize={15}
                            fontWeight={600}
                        >
                            Question:
                        </Typography>
                        <Passage active noScroll>
                            {group?.content || "NA"}
                        </Passage>
                    </>)}
                    {(group?.image) && (
                        <Box m={2} border="2px solid #E7E7E7">
                            <br />
                            <img
                                width={"100%"}
                                height={"auto"}
                                alt="question"
                                src={group?.image}
                            />
                        </Box>
                    )}
                </>)}

                {(type === "LISTENING") && (<>
                    <br />
                    <Typography
                        fontFamily="inter"
                        fontSize={15}
                        fontWeight={600}
                        mb={1}
                    >
                        Audio Passage :
                    </Typography>
                    <AudioElement audio={parts?.[0]?.audio} />

                    <br /> <br />
                    {(group?.content) && (<>
                        <Typography
                            fontFamily="inter"
                            fontSize={15}
                            fontWeight={600}
                        >
                            Question:
                        </Typography>
                        <Passage active noScroll>
                            {group?.content || "NA"}
                        </Passage>
                    </>)}
                    {(group?.image) && (
                        <Box m={2} border="2px solid #E7E7E7"> <br />
                            <img
                                width={"100%"}
                                height={"auto"}
                                alt="question"
                                src={group?.image}
                            />
                        </Box>
                    )}
                </>)}
            </>
        ) : null
    );
}