import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import useStore from "utils/reportStore";
import ReportShell from "../ReportShell";
import AllSectionScores from "./AllSectionScores";
import Analysis from "./Analysis";
import CommonObservations from "./CommonObservations";
import CustomTips from "./CustomTips";
import Descriptors from "./Descriptors";
import Details from "./Details";
import Heading from "./Heading";
import OverallPerformance from "./OverallPerformance";
import ParameterWiseProgress from "./ParameterWiseProgress";
import Questions from "./Questions";
import SectionShell from "./SectionShell";

const useStyle = makeStyles({
    container: {
        display: 'flex',
        gap: 10,
    },
    innerContainer: {
        width: "49%",
        borderRadius: "3px",
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        backgroundColor: '#ffffff',
        padding: '10px 20px',
    },
});

const OVERALL = -1;

export default function IeltsReport(props) {
    const classes = useStyle();

    const section = useStore((state) => state.section);
    const sections = useStore((state) => state.sections);
    const summary = useStore((state) => state.summary);
    const currentQuestion = useStore((state) => state.currentQuestion);
    const type = useStore((state) => state.sections?.[section]?.name?.toUpperCase());

    return (
        <ReportShell ielts>
            <Box className={classes.container}>
                <Box className={classes.innerContainer} >

                    <Heading active={section === OVERALL} />

                    <Details active={section === OVERALL} />

                    <OverallPerformance
                        active={section === OVERALL && sections.length >= 4}
                        score={summary?.overall?.score}
                    />

                    <AllSectionScores
                        active={section === OVERALL && sections.length >= 4}
                        scores={summary}
                    />

                    <Analysis active={section !== OVERALL && currentQuestion !== null} />

                    <SectionShell
                        active={section !== OVERALL && currentQuestion === null}
                        score={summary?.[type?.toLowerCase()]?.score}
                    >
                        {
                            (["WRITING", "SPEAKING"]?.includes(type)) && (
                                <>
                                    <ParameterWiseProgress
                                        parameters={summary?.[type?.toLowerCase()]}
                                        title='Paramerter wise progress'
                                    />
                                    <CommonObservations
                                        commonObservations={summary?.[type?.toLowerCase()]}
                                        title='Common Observations'
                                    />
                                </>
                            )
                        }

                    </SectionShell>

                </Box>
                <Box className={classes.innerContainer} >

                    <Questions
                        active={section !== OVERALL && currentQuestion !== null}
                    />

                    <Descriptors active={section === OVERALL} />

                    <CustomTips active={currentQuestion === null} />
                </Box>
            </Box>
        </ReportShell>
    );
}