import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { subscribeToAudioLevel } from 'utils/audio';


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    height: 30,
    width: 30,
    padding: theme.spacing(1),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  box: {
    transform: 'scaleY(.2)',
    height: '80%',
    width: 3,
    background: theme.palette.secondary['clr-900'],
    animationDuration: '1.2s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    borderRadius: '4px',
  },
  low: { animationName: '$low', },
  medium: { animationName: '$medium', },
  high: { animationName: '$high', },
  veryHigh: { animationName: '$veryHigh', },
  stop: {
    width: 6,
    height: '65%',
    borderRadius: '50%',
  },
  '@keyframes low': {
    '25%': {
      transform: 'scaleY(.3)',
    },
    '50%': {
      transform: 'scaleY(.2)',
    },
    '75%': {
      transform: 'scaleY(.4)',
    },
  },
  '@keyframes medium': {
    '25%': {
      transform: 'scaleY(.5)',
    },
    '50%': {
      transform: 'scaleY(.3)',
    },
    '75%': {
      transform: 'scaleY(.5)',
    },
  },
  '@keyframes high': {
    '25%': {
      transform: 'scaleY(.8)',
    },
    '50%': {
      transform: 'scaleY(.4)',
    },
    '75%': {
      transform: 'scaleY(.8)',
    },
  },
  '@keyframes veryHigh': {
    '25%': {
      transform: 'scaleY(1)',
    },
    '50%': {
      transform: 'scaleY(.4)',
    },
    '75%': {
      transform: 'scaleY(1.2)',
    },
  },
}));

export const AudioAnimation = ({audioLevel}) => {
  const classes = useStyles();

  const [sideBoxClass, setSideBoxClass] = React.useState(classes.stop);
  const [middleBoxClass, setMiddleBoxClass] = React.useState(classes.stop);

  React.useEffect(() => {
    if (audioLevel > 100) {
      setSideBoxClass(classes.high);
      setMiddleBoxClass(classes.veryHigh);
    }
    if (audioLevel > 50 && audioLevel < 100) {
      setSideBoxClass(classes.medium);
      setMiddleBoxClass(classes.high);

    }
    if (audioLevel < 50 && audioLevel > 10) {
      setSideBoxClass(classes.low);
      setMiddleBoxClass(classes.medium);

    }
    if (audioLevel < 10) {
      setSideBoxClass(classes.stop);
      setMiddleBoxClass(classes.stop);
    }
  }, [audioLevel]);

  return(
    <Box className={classes.boxContainer}>
      <Box className={`${classes.box} ${sideBoxClass}`}></Box>
      <Box className={`${classes.box} ${middleBoxClass}`}></Box>
      <Box className={`${classes.box} ${sideBoxClass}`}></Box>
    </Box>
  )
}

function MicTestAnimation({ start }) {
  const [audioLevel, setAudioLevel] = React.useState(0);
  const unsubscribeRef = React.useRef();

  const onLevel = (level) => setAudioLevel(level);

  const subscribe = React.useCallback(async () => {
    if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function')
      unsubscribeRef.current();

    if (!start) {
      unsubscribeRef.current = null;
      return
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });

      unsubscribeRef.current = subscribeToAudioLevel(stream, onLevel);
    } catch (error) {
      console.error(error);
    }
  }, [start]);

  React.useEffect(() => {
    console.log("DEBUG::ListeningTag-> subscribing", start);
    subscribe();
  }, [start]);

  return (
    <AudioAnimation audioLevel={audioLevel}/>
  );
}

export default MicTestAnimation;