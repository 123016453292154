import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";
import Title from "../Title";
import CommonObservations from "./CommonObservations";
import ParameterWiseProgress from "./ParameterWiseProgress";

import { InaudibleErrorInfo, MicrophoneInfo } from "./Info";

const useStyles = makeStyles({
    selected: {
        width: 15,
        height: 15,
        borderRadius: 15,
        backgroundColor: '#235598'
    },
    unSelected: {
        width: 15,
        height: 15,
        borderRadius: 15,
        border: '1px solid black'
    },
    title: {
        fontFamily: 'inter',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 5
    },
    answer: {
        fontFamily: 'inter',
        fontSize: 15,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.7)'
    },
});

export default function Analysis({ active }) {
    const classes = useStyles();
    const analyses = useStore((state) => state.analysis[state.section]);
    const {
        groups = [], questions = [], answers = [],
    } = useStore((state) => state.sections?.[state.section] || {});
    const currentQuestion = useStore((state) => state.currentQuestion);
    const type = useStore((state) =>
        state.sections[state.section]?.name?.toUpperCase()
    );

    const question = questions?.[currentQuestion];
    const group = groups?.[question?.group];

    const answerObj = analyses?.[currentQuestion]
    let answer = answerObj?.answer;
    let correctAnswer = answers?.[currentQuestion];
    let isCorrect = answerObj?.analysis?.score === 100;

    if (question?.type === 'scq') {
        correctAnswer = [correctAnswer];
        answer = [answer]
    }

    return (
        (active) ? (
            <>
                <Title title='Analysis' /> <br />
                {(['SPEAKING', 'WRITING']?.includes(type)) && (
                    <>
                        {(type === "SPEAKING" && (!analyses || answerObj?.analysis?.score === 0)) ?
                            <CenterFlexBox flexDirection='column' alignItems='flex-start'>
                                <InaudibleErrorInfo /> <br />
                                <MicrophoneInfo />
                            </CenterFlexBox> :
                            <>
                                <ParameterWiseProgress
                                    parameters={currentQuestion !== null && answerObj?.analysis}
                                    title='Paramerter wise progress'
                                /> <br />
                                <CommonObservations
                                    commonObservations={currentQuestion !== null && answerObj?.analysis}
                                    title='Common Observations'
                                />
                            </>
                        }
                    </>
                )}
                {(['READING', 'LISTENING']?.includes(type)) && (
                    <>
                        {<>
                            <Typography
                                variant='body01-medium' fontSize={15} fontWeight={600}
                                dangerouslySetInnerHTML={{ __html: group?.description || 'NA' }}
                            >
                            </Typography>
                            <Box display='flex' flexDirection={'row'} my={4}>
                                <Typography
                                    variant='body01-medium' mr={1}
                                    dangerouslySetInnerHTML={
                                        { __html: `Q${currentQuestion + 1}.` }
                                    }
                                >
                                </Typography>
                                {(['scq', 'mcq']?.includes(question?.type)) ?
                                    <Typography
                                        variant='body01-medium'
                                        dangerouslySetInnerHTML={{
                                            __html: question?.question || question?.content || 'NA'
                                        }}
                                    /> :
                                    <Typography
                                        variant='body01-medium'
                                    >
                                        ___________?
                                    </Typography>
                                }
                            </Box></>
                        }
                        {['fib', 'table_fib'].includes(question.type) && (<>
                            <Typography className={classes.title}>Your answer:</Typography>
                            <Typography className={classes.answer}>{answerObj?.answer || 'NA'}</Typography>
                            <br /><Typography className={classes.title}>Correct answer:</Typography>
                            <Typography className={classes.answer}>
                                {Array.isArray(correctAnswer) ?
                                    correctAnswer.join(' / ') :
                                    correctAnswer
                                }
                            </Typography>
                        </>)}
                        {(['scq', 'mcq']?.includes(question.type)) && <>
                            {questions?.[currentQuestion]?.options?.map((option, index) => (
                                <Box
                                    my={1} ml={1}
                                    display='flex'
                                    alignItems='center'
                                    gap={1}
                                    key={index}
                                >
                                    <Box className={answer?.includes(index) ?
                                        classes.selected :
                                        classes.unSelected}
                                    >
                                    </Box>
                                    <Typography
                                        fontFamily='inter'
                                        fontSize={16}
                                        fontWeight={400}
                                        color='rgba(0, 0, 0, 0.7)'
                                    >
                                        {option}
                                    </Typography>
                                    {correctAnswer?.includes(index) && <CheckOutlinedIcon color="success" />}
                                </Box>
                            ))}
                        </>
                        }

                        {
                            ['para_dnd', 'passage_dnd', 'img_dnd'].includes(question?.type) &&

                            <Box>
                                <Box>
                                    <Typography variant='body01-bold'>Options:</Typography>
                                    <Typography component='ol' variant='body01-regular'>
                                        {group.options.map((option) => {
                                            return <li>{option}</li>
                                        })}
                                    </Typography>
                                </Box>
                                <Box mt={4} display='flex' flexDirection='column' gap={2}>
                                    <Typography variant='body01-bold'>
                                        Your Answer :&nbsp;
                                        <span style={{ fontWeight: 400 }}>{group.options[answer] || 'NA'}</span>
                                    </Typography>
                                    <Typography variant='body01-bold'>
                                        Correct Answer :&nbsp;
                                        <span style={{ fontWeight: 400 }}>{
                                            Array.isArray(correctAnswer) ?
                                                correctAnswer.map(i => group.options[i]).join(' / ') :
                                                group.options[correctAnswer]

                                        }</span>
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        {
                            question?.type === 'tick_mark' &&
                            <Box>
                                <Box mt={4} display='flex' flexDirection='column' gap={2}>
                                    <Typography variant='body01-bold'>
                                        Your Answer :&nbsp;
                                        <span style={{ fontWeight: 400 }}>{answer || 'NA'}</span>
                                    </Typography>
                                    <Typography variant='body01-bold'>
                                        Correct Answer :&nbsp;
                                        <span style={{ fontWeight: 400 }}>{correctAnswer}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        }

                        <Box mt={2}>
                            <Typography
                                variant='body01-bold'
                                color={isCorrect ? '#03CA5E' : '#E98862'}
                            >
                                {isCorrect ?
                                    "Your answer matches the correct answer. Good job!" :
                                    "Your answer doesn't match with the correct answer. Need improvement!"
                                }
                            </Typography>
                        </Box>
                    </>
                )
                }
            </>
        ) : null
    );
}