import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function Title({ title, id }) {
  return (
    <Box
      backgroundColor='#D7E5FF'
      color='#02569D'
      borderRadius='6px'
      py={2}
      pl={2}
      id={id}
    >
      <Typography variant='h6-medium'>
        {title}
      </Typography>
    </Box>
  );
}

export default Title;