import { Box, Typography } from '@mui/material';
import React from 'react';

import { makeStyles } from "@mui/styles";
import { sanitizeTickMarkData } from 'utils';
import useStore from 'utils/ieltsStore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", gap: "4px", 
    flexDirection: 'column', 
    height: "95%" 
  },
  leftSection: {
    borderRight: "2px solid #000",
    height: '100%',
    overflow: 'auto',
  },
  rightSection: {
    padding: theme.spacing(2),
    overflow: 'auto',
    height: '100%'
  },
  content: {
    display: 'flex',
    gap: '4px',
    minHeight: '-webkit-fill-available'
  },
  tableContainer: {
    overflowX: "auto",
    padding: theme.spacing(2,0),
  },
  table: {
    minWidth: 500,
    maxWidth: 1000,
    border: "1px solid #ccc",
  },
  header: {
    backgroundColor: "#bfdbfe",
    fontWeight: "bold",
    padding: "12px",
    ...theme.typography['h6-bold']
  },
  row: {
    textAlign: "center",
    ...theme.typography['body01-bold']
  },
  labelCell: {
    textAlign: 'left',
    padding: theme.spacing(2,3),
  },
  headerCell: {
    padding: theme.spacing(2,3)
  },
  cellData: {
    padding: theme.spacing(2,0)
  },
  boldText: {
    fontWeight: "bold",
  },
}));

const Table = ({ data, headers, handleDataChange, questions, groupRange}) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.header}>
            {headers.slice(1).map((header, index) => (
              <th key={index} className={classes.headerCell}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} className={classes.row}>
              {/* <td className={classes.cellData}>{row.id}</td> */}
              <td className={classes.labelCell}>{row.label}</td>
              {headers.slice(2).map((col) => (
                <td key={col} className="border px-4 py-2 text-center">
                  <input 
                    type="checkbox" 
                    checked={questions[row.id-groupRange[0]]?.answer?.value===col} 
                    onChange={() => handleDataChange(row.id, col)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const TickMarkSection = ({ groupRange, group, questions }) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  React.useEffect(() => {
    const { headers, data } = sanitizeTickMarkData(group.tables);
    setHeaders(headers);
    setData(data);
  }, [group, questions]);

  const handleCheckboxChange = (id, column) => {
    questions[id-groupRange[0]].answer.value = column;
    setCurrentQuestion(id-1);

    setData(prevData =>
      prevData.map(row =>
        row.id === id
          ? { ...row, ...Object.fromEntries(headers.slice(2).map(col => [col, false])), [column]: true }
          : row
      )
    );
  };

  return (
    <Box className={classes.root}>
      <Box>
        {group?.questionsRange && (
          <Typography variant="body01-bold" mb={1}>
            Questions {groupRange?.join("-")}
          </Typography>
        )}
      </Box>
      <Box className={classes.content}>
        {
          group?.passage &&
            <Box className={classes.leftSection} style={{ width: group?.passage ? '50%' : '100%'}}>
              <Box>
                {group?.passage && (
                  <Typography 
                    variant="body01-bold" mb={2} 
                    dangerouslySetInnerHTML={{ __html: group?.passage }} 
                  />
                )}
              </Box>
            </Box>
        }

        <Box
          className={classes.rightSection}
          style={{ width: group?.passage ? '50%' : '100%'}}
        >
          <Box>
            {group?.description && (
              <Typography
                variant="body01-bold" mb={2} 
                dangerouslySetInnerHTML={{ __html: group?.description }} 
              />
            )}
          </Box>

          <Box className={classes.optionsRoot}>
            <Table 
              data={data} 
              headers={headers} 
              handleDataChange={handleCheckboxChange}
              questions={questions}
              groupRange={groupRange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default TickMarkSection;