import { Box } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex',
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-600'],
    width: 'fit-content',
    alignItems: 'center',
    borderRadius: '4px',
    height: '38px',
  },
  sections: {
    height: '100%',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  centerSection: {
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.neutral['clr-600'],
    cursor: 'default',
  },
  icon: {
    padding: 0,
    margin: 0,
    fontSize: '22px',
    color: theme.palette.primary['clr-300']
  }
}));

const ZoomInOut = () => {
  const classes = useStyles();
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.05, 1.1);
      document.body.style.zoom = newZoom;
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.05, 0.9);
      document.body.style.zoom = newZoom;
      return newZoom;
    });
  };

  return (
    <Box className={classes.root}>
      <Box onClick={handleZoomOut} className={classes.sections}>
        <RemoveIcon className={classes.icon}/>
      </Box>
      <Box className={`${classes.sections} ${classes.centerSection}`}>
        aA
      </Box>
      <Box onClick={handleZoomIn} className={classes.sections}>
        <AddIcon className={classes.icon}/>
      </Box>
    </Box>
  );
};

export default ZoomInOut;
