import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Screens from "./screens";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const STAGES = ['prod', 'staging'];

if (STAGES.includes(process.env.REACT_APP_STAGE) && process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

if (process.env.REACT_APP_STAGE === "prod") {
  require("./analytics");
}

ReactDOM.render(
  <React.StrictMode>
    <Screens />
  </React.StrictMode>,
  document.getElementById("root")
);
