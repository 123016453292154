import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

import CenterFlexBox from "components/CenterFlexBox";
import CountDown from "components/CountDown";
import beep, { playAudio } from 'utils/beep';
import { useConnected, useSnackbar } from 'contexts';
import useStore from 'utils/ieltsStore';
import CustomButton from 'components/CustomButton';
import VideoPreview from 'components/VideoPreview';
import MicTestAnimation, { AudioAnimation } from 'components/MicTestAnimation';

const isTestingEnv = process.env.REACT_APP_TESTING || false;

const useStyles = makeStyles(theme=>({
  root: {
    height: '-webkit-fill-available', 
    width: '100%',
    display: 'flex', 
    gap: theme.spacing(5),
    padding: theme.spacing(5),
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
  },
  leftImg: {
    alignSelf: 'center',
    width: '100%',
    height: '100%'
  },
  rightSection: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  userActions: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2)
  },
  cameraSection: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '8px'
  },
  micAnime: {
    position: 'absolute',
    top: '30px',
    right: '30px'
  },
  cueBlock: {
    width: '100%', height: '100%', overflow: 'auto',
    backgroundColor: '#F3F2F2', padding: '10px',
    border: '2px solid #000', borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column'
  },
  cueBlockHeader: {
    height: '30px',
    display: 'flex',
    borderBottom: '2px solid #000',
    alignItems: 'center',
    marginBottom: '10px'
  }
}));


function Block({ disable = false, children, ...props }) {

  return (
    <CenterFlexBox
      minHeight={250} height={'50vh'} width='35%'
      borderRadius={'16px'} flexDirection='column'
      border={(disable ? '2px solid #CCD4DE' : '2px solid #9DE4F2')}
      boxShadow={(disable ? 'none' : '0px 0px 16px 0px #52B8CC4D')}
      {...props}
    >
      {children}
    </CenterFlexBox>
  );
}

const SpeakingTime = 15;

const CueBlock = ({ htmlData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cueBlock}>
      <Box className={classes.cueBlockHeader}>
        <Typography variant='h6-bold'>Cue Card</Typography>
      </Box>
      <Box flexGrow={1}
        dangerouslySetInnerHTML={{ __html: htmlData }}
      />
    </Box>
  )
}

function RecordQuestion({ 
  active, question, analyseAnswer, speechRecorder, image
}) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const {connected} = useConnected();
  const questionTimeTakenRef = React.useRef(0);
  const questionAudioRef = React.useRef(null);
  
  const currentQuestion = useStore(state => state.currentQuestion);
  const cueAudioPlayed = useStore(state => state.cueAudioPlayed);
  const setCueAudioPlayed = useStore(state => state.setCueAudioPlayed);
  const [speakingTime, setSpeakingTime] = React.useState(0);
  const [speakingTotalTime, setSpeakingTotalTime] = React.useState(0);

  const setRecording = useStore((state) => state.setRecording);

  const { groups, questions } = useStore(
    state => state.sections[state.section]
  );

  const group = React.useMemo(
    () => groups?.[question.group], [question, groups]
  );

  const handleSpeechSuccess = React.useCallback((result) => {
    const answer = {
      ...question.answer,
      timeTaken: question.answer.timeTaken,
      ...result,
    };
  
    question.answer = answer;
  
    analyseAnswer(answer);
  }, [question, analyseAnswer]);
  
  const submitAnswer = React.useCallback(() => {
    setRecording(false);
    setSpeakingTime(0);
    const timeTaken = Math.round(
      (Date.now() - questionTimeTakenRef.current) / 1000
    );

    question.answer.submitted = true;
    question.answer.timeTaken = timeTaken;
    question.answer.recording = false;

    if (speechRecorder.current) {
      speechRecorder.current.stop(handleSpeechSuccess);
    }
  }, [handleSpeechSuccess, question, speechRecorder]);

  React.useEffect(()=>{
    setSpeakingTotalTime(question.time);
  }, [question]);

  const recordAnswer = React.useCallback(() => {
    if (active) {
      setRecording(true);
      setSpeakingTime(questions[currentQuestion].time);

      questionTimeTakenRef.current = Date.now();

      speechRecorder.current?.start();

    }
  }, [speechRecorder, question, active, currentQuestion]);

  const handleQuestionAudioEnded = React.useCallback(() => {
    beep();
    recordAnswer();
  }, [recordAnswer]);

  /**
  * Effect to play audio automatically for each question once
  */
  React.useEffect(() => {
    if (connected && cueAudioPlayed[group?.part]) {
      if(question?.audio){
        questionAudioRef.current = playAudio(question?.audio || null, (e) => {
          snackbar.error("Unable to play audio! Check your network connection");
          handleQuestionAudioEnded();
        });
        
        questionAudioRef.current.addEventListener('ended', handleQuestionAudioEnded);
        
        return () => {
          questionAudioRef.current.currentTime = 0;
          questionAudioRef.current.pause();
        };
      }else{
        setTimeout(() => {
          handleQuestionAudioEnded();
        }, 60000);
      }
    }
  }, [question, connected, cueAudioPlayed]);

  // cue audio
  React.useEffect(()=>{
    if(connected && !cueAudioPlayed[group.part] && group?.cueAudio){
      const audio = playAudio(group.cueAudio || null, (e) => {
        snackbar.error("Unable to play audio! Check your network connection");
      });

      audio.addEventListener("ended", () => {
        setTimeout(() => {
          const newData = cueAudioPlayed.map((val, index) => (index === group?.part ? true : val));
          setCueAudioPlayed(newData);
        }, 2000); // 2-second delay
      });

      return () => {
        audio.currentTime = 0;
        audio.pause();
      };
    }
  },[connected, group]);

  const endQuestionAudio = React.useCallback(() => {
    questionAudioRef.current.currentTime = 0;
    questionAudioRef.current.pause();
    handleQuestionAudioEnded();
  },[]);

  return (
    <Box className={classes.root}>
      <Block
        justifyContent='space-evenly' disable={!!speakingTime}
        style={{padding: '10px', overflow: 'hidden', position: 'relative'}}
      >
        {
          !question?.audio && (cueAudioPlayed[question.part]) ?
            <CueBlock htmlData={group?.description} />
            :
            <img
              src={image ?? "https://assets.languify.in/images/interviewerFrame.png"}
              alt="ai-bot"
              className={classes.leftImg}
            />
        }
        <Box className={classes.micAnime}>
          {
            speakingTime || group?.description?.length
              ? null
              : <AudioAnimation audioLevel={70}/>
          }
        </Box>

        {
          isTestingEnv && !speakingTime && cueAudioPlayed[question.part] && (
            <Box style={{position: 'absolute', bottom: 20, right: '50%'}}>
              <Button variant='contained' onClick={endQuestionAudio}> skip</Button>
            </Box>
          )
        }
      </Block>

      <Block 
        justifyContent='space-evenly'
        disable={!speakingTime}
        style={{padding: '20px', position: 'relative'}}
      >
        <Box className={classes.rightSection}>
          <Box className={classes.cameraSection}>
            <VideoPreview/>
          </Box>
          <Box className={classes.userActions}>
            <CustomButton
              onClick={submitAnswer}
              style={{ 
                visibility: !!speakingTime ? 'visible' : 'hidden',
                marginTop: 16,
                backgroundColor: '#E1E3E5',
                color: '#000'
              }}
              variant="contained"
            >
              Save Response
            </CustomButton>
            <Typography
              variant='body02-bold'
              style={{
                visibility: !!speakingTime ? 'visible' : 'hidden',
              }}
            >
              Available time: &nbsp;
              {!!speakingTime && (
                <CountDown
                  totalTime={speakingTotalTime}
                  onTimeout={submitAnswer}
                  color="black"
                  component="span"
                />
              )}
            </Typography>
          </Box>
          <Box className={classes.micAnime}>
        </Box>
        </Box>
        <Box className={classes.micAnime}>
          {
            speakingTime
              ? <MicTestAnimation start={true}/>
              : null
          }
        </Box>
      </Block>
    </Box>
  );
};

export default RecordQuestion;
