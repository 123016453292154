import { makeStyles } from '@mui/styles';
import React from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MicIcon from '@mui/icons-material/Mic';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Box,
  Divider, LinearProgress, TextField, Typography
} from '@mui/material';

import {
  askPermission, calculateStringMatchPercentage,
  checkPermission, checkPermissionState, fetchMediaDevices, openSystemPrivacySettings,
  openSystemSoundSettings, scrollToElementById,
} from '../utils';
import { generateTranscript } from 'services';
import Chip from './Chip';
import { 
  troubleshootMicError, troubleshootSuggestions, troubleshootTranscriptError 
} from './IELTSMock/constant';
import { playOrStopAudio, subscribeToAudioLevel } from 'utils/audio';
import CustomButton from './CustomButton';
import BrowserSettingsDialog from 'dialogs/BrowserSettingsDialog';
import { useSnackbar } from 'contexts';

const isTestingEnv = process.env.REACT_APP_TESTING || false;

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    margin: "0",
    width: '100%',
    borderRadius: "4px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "4px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "4px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "4px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
  root: {
    height: '100%',
    backgroundColor: '#fff',
    paddingBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checklist: {
    marginTop: '8px',
    display: 'flex',
    gap: '8px'
  },
  micTest: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  suggestionBox: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: '#F5F9FF',
    padding: '8px',
    borderRadius: '8px'
  },
  suggestionItems: {
    fontSize: '12px',
    color: '#4E5661',
  },
  micRecordComp: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  micRecord: {
    display: 'flex',
    gap: '4px',
    width: '50%'
  },
  mic: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  switchMicBtn: {
    textTransform: 'none',
    padding: 0,
    fontSize: '11px',
    textDecoration: 'underline',
  },
  proceedBtn: {
    marginTop: '20px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4px',
    borderRadius: '8px',
    backgroundColor: '#003E8C',
    alignItems: 'center'
  },
  questions: {
    display: 'flex', flexDirection: 'column', gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 2)
  },
}));

const sx = {
  danger: { fontSize: '14px', color: '#C51407' },
  success: { fontSize: '14px', color: '#00664A' }
}

export const MicPermissions = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
}


function Questions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(0);
  const [openBrowserSettings, setOpenBrowserSettings] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const QuestionAnswers = [
    {
      question: "How to take the microphone test?",
      answer: "Inside the Troubleshoot >> Microphone section, first check the microphone in use, if it is correct, click on the 'Record' button and repeat the mentioned sentence clearly. Click on 'Stop' once you complete speaking. Listen to your recording and check the microphone and transcript status. If both are green, you are ready to take the attempt.",
    },
    {
      question: "What to do if my microphone is not working?",
      answer: <>
        <Typography variant='body01-semiBold' component="ul" sx={{ ml: 4, pl: 2, mb: 2 }}>
          <li>Check that your external microphone is properly connected & in appropriate proximity.</li>
          <li>Don’t use faulty or damaged microphone ports.</li>
          <li>Verify that the correct microphone is selected.</li>
          <li>The microphone itself may be damaged or non-functional.</li>
          <li>Another application might be using or blocking the microphone.</li>
          <li>Some antivirus programs may block microphone access for security reasons.</li>
          <li>Corporate system sometimes block access for security reasons.</li>
        </Typography>
      </>,
    },
    {
      question: "How to resolve transcript issues?",
      answer: <>
        <Typography variant='body01-semiBold' component="ul" sx={{ ml: 4, pl: 2, mb: 2 }}>
          <li>Make sure microphone is in appropriate proximity.</li>
          <li>Low-quality or faulty microphones may not capture clear audio. Use a good quality microphone.</li>
          <li>Being too far or too close to the microphone can affect input quality. Maintain an optimal distance from the mic.</li>
          <li>Ambient sounds (e.g., fans, traffic, conversations) can interfere with audio clarity.</li>
          <li>Try to avoid mumbled, fast, or unclear speech.</li>
          <li>Other running applications may interfere with the mic input, close them & try again.</li>
          <li>Incorrect microphone sensitivity of microphone in computer (too low or too high) can distort the input
            . Open your system’s Sound Settings for checking the input levels.
          </li>
        </Typography>
      </>,
    },
    {
      question: "How to enable microphone in browser?",
      answer: <Typography variant='body01-semiBold'>Click on <span onClick={() => setOpenBrowserSettings(true)} style={{ cursor: 'pointer', color: '#1961BF', textDecoration: 'underline', fontWeight: 600 }}>Enable microphone in browser</span> & follow the steps.</Typography>,
    },
    {
      question: "How to enable microphone access for the browser in System?",
      answer: <Typography variant='body01-semiBold'>Open your system’s Sound Settings and enable microphone access for your browser.</Typography>,
    },
    {
      question: "How to enable microphone that is blocked by system settings in computer?",
      answer: <Typography variant='body01-semiBold'>Open your system’s Privacy & Security settings. (For Mac users: Security & Privacy). Unmute your microphone and adjust its level.</Typography>,
    },
    {
      question: "How to change microphone",
      answer: <>
        <Typography variant='body01-semiBold'>
          To switch your microphone in <b>Chrome, Brave, and Edge.</b> Follow these steps;
        </Typography>
        <Typography variant='body01-semiBold' component="ol" sx={{ ml: 4, pl: 2, mb: 2 }}>
          <li>Click on the     or      in the browser navbar top-right corner.</li>
          <li>Select <b>Settings</b> from the dropdown menu.</li>
          <li>In the settings menu, click on <b>Privacy and Security.</b></li>
          <li>Select <b>Site Settings.</b></li>
          <li>Scroll to the Permissions section.</li>
          <li>Click on <b>Microphone.</b></li>
          <li>Use the dropdown menu to select your preferred microphone.</li>
        </Typography>
        <Divider />
        <br />
        <Typography variant='body01-semiBold' mt={2}>
          In case of Mozilla Firefox;
        </Typography>
        <Typography variant='body01-semiBold' component="ul" sx={{ ml: 4, pl: 2, mb: 2 }}>
          <li>you can use another microphone.</li>
          <li>Try to use another browser from above list.</li>
          <li>If issue persists try to attempt on another device.</li>
        </Typography>
      </>,
    },
  ];

  return (
    <Box mt={8} mb={4}>
      <Typography variant='h4-semiBold'>
        Frequently Asked Questions
      </Typography>
      <Divider style={{ borderColor: '#8692A3' }} />
      <Box className={classes.questions}>
        {QuestionAnswers.map(({ question, answer }, i) => (
          <Accordion
            key={i}
            elevation={0}
            expanded={expanded === i} onChange={handleChange(i)}
            style={{ border: '2px solid #BFCAD9' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} mt={1}>
              <Typography variant='h6-semiBold'>{i + 1}. {question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body01-semiBold'>{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <BrowserSettingsDialog
        open={openBrowserSettings}
        onClose={() => setOpenBrowserSettings(false)}
      />
    </Box>
  );
}

const PermissionChips = ({ success = false, content = "", onClick = () => { } }) => {

  return (
    <Chip
      sx={!success && { cursor: 'pointer' }}
      onClick={() => { !success && onClick(true) }}
      content={content}
      bgColor={success ? '#DCFCE7' : '#FFE6E3'}
      color={success ? '#00664A' : '#C51407'}
      startIcon={
        success
          ? <CheckCircleOutlinedIcon style={sx.success} />
          : <ErrorOutlineOutlinedIcon style={sx.danger} />
      }
    />
  )
}

const Microphone = ({ setProceed }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [audioLevel, setAudioLevel] = React.useState(0);
  const [currentDevice, setCurrentDevice] = React.useState("");
  const [devices, setDevices] = React.useState([]);
  const [permission, setPermission] = React.useState(null);
  const [recording, setRecording] = React.useState();
  const mediaRecorder = React.useRef(null);
  const [transcript, setTranscript] = React.useState("");
  const [micWorking, setMicWorking] = React.useState(false);
  const [muted, setMuted] = React.useState(false);
  const [transcriptMatch, setTranscriptMatch] = React.useState(false);
  const [analysing, setAnalysing] = React.useState(false);
  const [analysed, setAnalysed] = React.useState(false);
  const [openBrowserSettings, setOpenBrowserSettings] = React.useState(false);
  const [systemDenied, setSystemDenied] = React.useState(false);
  const [audioPlaying, setAudioPlaying] = React.useState(false);
  const devicesRef = React.useRef({ current: [] });
  const unsubscribeRef = React.useRef();
  const audioInstanceRef = React.useRef();
  const mediaStreamRef = React.useRef(null);

  const subscribe = React.useCallback(async () => {
    try {
        mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

        const track = mediaStreamRef.current.getAudioTracks()[0];
        const handleMute = () => {
          setMicWorking(false);
          setMuted(true);
        };
        
        const handleUnmute = () => {
          setMicWorking(true);
          setMuted(false);
        };

        if (track.muted) {
          handleMute();
        } else {
          handleUnmute();
        }

        track.onmute = handleMute;
        track.onunmute = handleUnmute;

        unsubscribeRef.current = subscribeToAudioLevel(mediaStreamRef.current, (level) => {
          setAudioLevel(level);
          if (level) setMicWorking(true);
        });
    } catch (error) {
      console.error('Error in subscribing: ', error);
      if (devicesRef.current.length) {
        setMicWorking(false);
        setSystemDenied(true);
      }
    }
  }, []);

  const fetchDevices = async () => {
    fetchMediaDevices((data)=>{
      setDevices(data);
      devicesRef.current = data;
    }, setCurrentDevice)
  };

  const getPermissionStatus = async () => {
    try {
      checkPermissionState("microphone", (newState) => {
          console.log("Microphone permission changed to:", newState);
          setPermission(newState);
        }).then((state) => {
          console.log("Initial microphone permission state:", state);
          setPermission(state);
        });
    } catch (error) {
      console.error('Error checking permissions:', error);
    }
  };

  const requestPermission = async () => {
    const isMicAllowed = await checkPermission("microphone");
    fetchDevices();

    if (!isMicAllowed) {
      const isPermitted = await askPermission({ video: true, audio: true });
      if (!isPermitted) return;
    }

    await getPermissionStatus();
    subscribe();
  }

  React.useEffect(() => {
    (async () => {
      await requestPermission();
    })();
  }, []);

  React.useEffect(() => {
    const handleDeviceChange = async () => {
      await fetchDevices();
      await subscribe();
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
  }, []);

  const analyseAndGenerateTranscript = React.useCallback(async (audioBlob) => {
    console.log("Controls::handleSpeechSuccess->", audioBlob);
    setAnalysing(true);

    try {
      await new Promise((resolve, reject) => {
        generateTranscript({ audioBlob }, (e, data) => {
          if (e === 'analysed') {
            console.log("Analysed Data: ", data);
            setTranscript(data.result.transcript);
            setAnalysed(true);
            resolve();
          } else {
            reject(e);
          }
        });
      });
    } catch (error) {
      console.error("Error during analysis:", error);
    } finally {
      setAnalysing(false);
    }
  }, []);

  const handleClick = React.useCallback(async () => {
    if (recording) {
      console.log("State: ", mediaRecorder.current.state);
      // stop if recording
      if (mediaRecorder.current.state === 'recording')
        mediaRecorder.current.stop();

    } else {
      mediaRecorder.current = new MediaRecorder(mediaStreamRef.current);
      console.log("State: ", mediaRecorder.current.state);

      const audioChunks = [];

      mediaRecorder.current.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });

      mediaRecorder.current.addEventListener("stop", async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        analyseAndGenerateTranscript(audioBlob);

        try {
          audioInstanceRef.current = await playOrStopAudio({ source: audioBlob });
          if (audioInstanceRef.current) {
            console.log("Audio playing");
            setAudioPlaying(true);
            audioInstanceRef.current.onended = () => {
              console.log("Audio finished playing");
              setAudioPlaying(false);
            };
          }
        } catch (error) {
          snackbar.error(error.message);
        }
      });

      mediaRecorder.current?.start();
      // Stop recording after 10 seconds
      setTimeout(() => {
        if (mediaRecorder.current.state === 'recording') {
          mediaRecorder.current.stop();
          setRecording(false);
        }
      }, 10000);
    }
    setRecording(r => !r);
  }, [recording]);

  React.useEffect(() => {
    if (audioLevel) {
      setMicWorking(true);
    }
    if (isTestingEnv) {
      setProceed(true);
    }
  }, [audioLevel]);

  React.useEffect(() => {
    if (!transcript) return;
    const match = calculateStringMatchPercentage(transcript, "This is a test recording for my microphone. Can you hear me clearly?");
    if (match > 70) {
      setTranscriptMatch(true);
      setProceed(true);
    }

    if (analysed) {
      if (!micWorking || match <= 70) {
        scrollToElementById('suggestions');
      }
    }
  }, [transcript, analysed, micWorking]);

  const {
    isRecordingDisabled,
    buttonStyle,
    buttonVariant,
    buttonLabel
  } = React.useMemo(() => {
    const isDisabled = permission !== MicPermissions.GRANTED || analysing || audioPlaying || systemDenied;
  
    return {
      isRecordingDisabled: isDisabled,
      buttonStyle: {
        backgroundColor: !isDisabled && !recording && !analysing && !analysed && !audioPlaying ? '#C51407' : undefined,
      },
      buttonVariant: recording || analysed ? 'outlined' : 'contained',
      buttonLabel: recording 
        ? "Stop" 
        : analysing 
          ? 'Analyzing' 
          : audioPlaying 
            ? "Playing" 
            : "Record",
    };
  }, [permission, analysing, audioPlaying, systemDenied, recording, analysed]);

  return (
    <Box mt={2}>
      <Box mb={2}>
        <Box className={classes.checklist}>
          <PermissionChips
            content='Browser permissions'
            success={permission === MicPermissions.GRANTED}
            onClick={setOpenBrowserSettings}
          />
          <PermissionChips
            content={'System permissions'}
            success={!(!devices.length || systemDenied || muted)}
            onClick={openSystemSoundSettings}
          />
        </Box>
        {
          (!devices.length || systemDenied || muted) &&
          <Box mt={2}>
            <Typography variant='body01-bold' color='danger.clr-700'>
              {
                muted 
                  ? 'Microphone is muted by the system settings.' 
                  : 'No microphone is detected. Please connect one, If exists make it enabled in the system settings.'
              }
            </Typography>
          </Box>
        }
      </Box>

      <Divider style={{ borderColor: '#CCD4DE' }} />

      <Box className={classes.micTest}>
        <Typography variant='h5-medium'>
          Repeat & record the sentence to test your microphone
        </Typography>
        <Typography variant='h3-medium' color={'primary.main'} mt={1}>
          This is a test recording for my microphone. Can you hear me clearly?
        </Typography>
      </Box>

      <Box className={classes.micRecordComp}>
        <Typography variant='body01-bold'>Microphone in use</Typography>
        <Box className={classes.micRecord}>
          <TextField
            className={classes.textFieldRoot}
            disabled
            value={currentDevice}
            size="small"
          />
          <CustomButton
            style={buttonStyle}
            size="small" variant={buttonVariant}
            onClick={handleClick}
            disabled={isRecordingDisabled}
          >
            {buttonLabel}
          </CustomButton>
        </Box>
        <Box className={classes.mic}>
          <MicIcon style={{ fontSize: '18px' }} />
          <Box sx={{ width: '120px' }}>
            <LinearProgress
              variant="determinate" value={audioLevel}
              style={{ borderRadius: '10px' }}
            />
          </Box>
        </Box>
      </Box>

      {
        analysed &&
        <Box mt={2} id='checklist'>
          <Box className={classes.checklist}>
            <PermissionChips
              content={'Microphone'}
              success={permission === MicPermissions.GRANTED && micWorking}
            />
            <PermissionChips
              content={'Transcription'}
              success={transcriptMatch}
            />
          </Box>
        </Box>
      }

      <Suggestions
        header={!analysed || transcriptMatch ? 'Suggestions' : 'How to fix'}
        data={
          analysed && micWorking && !transcriptMatch
            ? troubleshootTranscriptError
            : analysed && !micWorking && !transcriptMatch
              ? troubleshootMicError
              : troubleshootSuggestions
        }
      />

      <BrowserSettingsDialog
        open={openBrowserSettings}
        onClose={() => setOpenBrowserSettings(false)}
      />
    </Box>
  )
}

const Suggestions = ({ data, header = 'Suggestions' }) => {
  const classes = useStyles();

  return (
    <Box className={classes.suggestionBox} id='suggestions'>
      <Typography variant='body01-bold'>{header}:</Typography>
      <Typography variant='body01-bold' color='neutral.clr-500' component="ul" sx={{ ml: 4, pl: 2, mb: 2 }}>
        {
          data.map((item, i) => (
            <li key={i}>{item}</li>
          ))
        }
      </Typography>
    </Box>
  )
}

const MicrophoneTroubleshoot = ({ handleSuccess }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Microphone setProceed={handleSuccess} />
      <Questions />
    </Box>
  );
}

export default MicrophoneTroubleshoot;