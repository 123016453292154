import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const SectionColors = {
  "READING": { color: "#0F8199", backgroundColor: "#DEF7FC" },
  "LISTENING": { color: "#5E4DB2", backgroundColor: "#F4F0FF" },
  "SPEAKING": { color: "#0F8199", backgroundColor: "#DEF7FC" },
  "WRITING": { color: "#5E4DB2", backgroundColor: "#F4F0FF" },
};

function SectionChip({ name, hideInfoIcon, onViewInfo, onHideInfo }) {
  const uName = name?.trim()?.toUpperCase();

  return (
    <Typography
      component="div" px={2} py={1} style={{
        ...SectionColors[uName], borderRadius: 12, width: "fit-content",
      }}
      variant='body02-semiBold'
    >
      {name}
      {!hideInfoIcon && (
        <IconButton
          sx={{ ml: 1, p: 0 }} onMouseEnter={onViewInfo}
          onMouseLeave={onHideInfo}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    </Typography>
  );
}

export default SectionChip;