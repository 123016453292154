import React, { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HeightIcon from '@mui/icons-material/Height';

const useStyles = makeStyles(theme=>({
  root: {
    width: '2px',
    height: '100%',
    backgroundColor: '#000',
    cursor: 'ew-resize',
    display: 'flex',
    alignItems: 'center',
  },
  draggerBox: { 
    border: '2px solid #003E8C',
    padding: '5px',
    marginLeft: '-20px',
    cursor: 'pointer', 
    backgroundColor: '#fff',
    width: '36px', height: '36px',
    display: 'flex', 
    alignItems: 'center', justifyContent: 'center'
  }
}));

const SectionSlider = ({ setLeftWidth }) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth > 10 && newWidth < 90) {
      setLeftWidth(newWidth);
    }
  };

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
      <Box className={classes.root} onMouseDown={handleMouseDown}>
        <Box className={classes.draggerBox} display='block'>
          <HeightIcon style={{ transform: 'rotate(90deg)', fontSize: '30px' }} color='primary'/>
        </Box>
      </Box>
  );
};

export default SectionSlider;
