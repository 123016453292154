import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>({
  root: {
    display:"flex" ,
    width:"90%" ,
    flexGrow: 1 ,
    marginTop: theme.spacing(2),
    overflow:"hidden",
    justifyContent: 'center'
  },
  container: {
    marginTop: theme.spacing(2),
    width:"100%" ,
    height:"-webkit-fill-available",
    overflow: 'auto',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-200'],
    padding: theme.spacing(4)
  }
}))

function QuestionsContainer({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {children}
      </Box>
    </Box>
  );
}

export default QuestionsContainer;