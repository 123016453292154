import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme=>({
  root: { 
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorBox: {
    height: '250px',
    width: '550px',
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    border: '1px solid #CCD4DE',
    borderRadius: '8px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.danger['clr-100'],
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")] : {
      width: '90%'
    }
  }
}));


export function ErrorContainer({
  image="https://assets.languify.in/images/error-icon.svg",
  heading="Something went wrong!",
  subheading="Go back and retry to attempt.",
  attempted=false
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.errorBox}>
        <img src={image} alt="error" style={{ marginBottom: '10px' }}/>
        <Typography variant="h5-medium">
          {
            attempted
              ? 'Test already attempted'
              : heading
          }
        </Typography>
        <Typography variant="body01-semiBold">
          {
            attempted
              ? 'It looks like this test has already been started or completed. Each test link can only be used once.'
              : subheading
          }
        </Typography>
      </Box>
    </Box>
  );
};

function Error() {
  const location = useLocation();
  const { attempted } = location.state || {};

  return (
    <ErrorContainer attempted={attempted}/>
  );
};

export default Error;